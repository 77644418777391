<template>
  <div
    class="dropdown-item"
    @click="clicked"
    :class="disabled && tooltip ? 'disabled-tooltip' : disabled ? 'disabled' : ''"
  >
    <slot>
      <tooltip v-if="tooltip" :content="tooltip" placement="left">
        <span class="dropdown-item-title">
          <slot name="title">{{ title }}</slot>
        </span>
      </tooltip>
      <span v-else class="dropdown-item-title">
        <slot name="title">{{ title }}</slot>
      </span>

      <div v-if="hasToggle" class="dropdown-item-detail">
        <nice-switch :model-value="toggleActive" :width="30" />
      </div>
    </slot>
    <nice-label v-if="highlightAsNew" color="#00ff00" size="mini" class="mr-3 uppercase">
      {{ $t("labels.new") }}
    </nice-label>
  </div>
</template>

<script>
import useEnvironment from "@/plugins/use-environment"

export default {
  emits: ["click", "update:toggle-active"],
  setup() {
    const { isBackdoor } = useEnvironment()
    return { isBackdoor }
  },
  props: {
    title: {
      required: false,
      type: String,
    },
    tooltip: {
      type: String,
    },
    hasToggle: {
      type: Boolean,
      default: false,
    },
    toggleActive: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    highlightAsNew: {
      type: Boolean,
      default: false,
    },
    ajax: {
      type: Boolean,
      default: false,
    },
    sensitive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clicked(e) {
      if (this.disabled) return
      if (this.sensitive && this.isBackdoor) return App.alert("Aktion nicht erlaubt von Support")

      this.$emit("click")

      if (this.hasToggle) {
        e.stopPropagation()
        this.$emit("update:toggle-active", !this.toggleActive)
      } else if (this.link && this.newTab) {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          rel: "noopener noreferrer",
          href: this.link,
        }).click()
      } else if (this.link) {
        this.$router.push(this.link)
      }
    },
  },
}
</script>

<style scoped>
.dropdown-item {
  min-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

a {
  color: inherit;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.07);
  /* color: inherit; */
}

.dropdown-item-title {
  outline: none;
  padding-left: 14px;
  padding-right: 14px;
  flex: 1;
  word-break: normal;
}
.dropdown-item-detail {
  padding-right: 14px;
}

.disabled-tooltip {
  opacity: 0.45;
  cursor: default;
}
.disabled-tooltip:hover {
  background-color: rgba(0, 0, 0, 0);
}
</style>

import { LocaleKey } from "./locales"

export const LOCALE_FLAGS: Record<LocaleKey, string> = {
  en: "🇬🇧",
  de: "🇩🇪",
  hu: "🇭🇺",
  es: "🇪🇸",
  fr: "🇫🇷",
  it: "🇮🇹",
  ru: "🇷🇺",
  ar: "🇸🇦",
  "zh-CN": "🇨🇳",
}

import { FormFieldType as FFT, FormField, FormFieldScope } from "@/interfaces"

const pLabelOptions = (fieldName: string) => {
  return ({ db }) => {
    const mapping = db.shopData.propertyOptionsMapping[fieldName]
    return Object.keys(mapping).map(key => ({ id: key, name: mapping[key] }))
  }
}

const FORM_FIELDS: Record<string, FormField> = {
  location_ids: {
    component: "saved-query-locations",
  },
  cities: {
    component: "saved-query-cities",
  },
  regions: {
    type: FFT.multiselect,
    options: ({ db }) => db.shopData.regions.map(r => ({ id: r, name: r })),
  },
  swimming_pool: { type: FFT.yesNo },
  geometry: {
    component: "saved-query-geometry",
  },
  marketing_type: {
    type: FFT.dropdown,
    options: ({ t }) => [
      { id: null, name: t("client.queries.form.both") },
      { id: "BUY", name: t("client.queries.form.buy") },
      { id: "RENT", name: t("client.queries.form.rent") },
    ],
  },
  rs_type_categories: {
    component: "saved-query-categories",
  },
  group_ids: {
    options: ({ db }) => {
      const superGroups = db.get("superGroups")
      return db
        .get("propertyGroups")
        .map(g => {
          const superGroup = superGroups.find(sg => sg.id == g.superGroupId)
          return {
            id: g.id,
            name: superGroup ? `${superGroup.name}: ${g.name}` : g.name,
          }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    type: FFT.multiselect,
  },
  note: { type: FFT.text },
  public_note: { type: FFT.text },

  price: { type: FFT.range, unit: "currency" },
  living_space: { type: FFT.range, unit: "area" },
  number_of_rooms: { type: FFT.range },
  number_of_bed_rooms: { type: FFT.range },
  number_of_bath_rooms: { type: FFT.range },
  base_rent: { type: FFT.range, unit: "currency" },
  floor: { type: FFT.range },
  plot_area: { type: FFT.range, unit: "area" },
  construction_year: { type: FFT.range, unit: "year" },
  condition: { type: FFT.multiselect, options: pLabelOptions("condition") },
  lift: { type: FFT.yesNo },
  balcony: { type: FFT.yesNo },
  garden: { type: FFT.yesNo },
  built_in_kitchen: { type: FFT.yesNo },
  cellar: { type: FFT.yesNo },
  rented: { type: FFT.yesNo },
  parking_space: { type: FFT.yesNo },
  barrier_free: { type: FFT.yesNo },
  monument: { type: FFT.yesNo },
  total_floor_space: { type: FFT.range, unit: "area" },
  price_per_sqm: { type: FFT.range, unit: "currency" },
  net_floor_space: { type: FFT.range, unit: "area" },
  price_multiplier: { type: FFT.range },
  yield_actual: { type: FFT.range },
  investment_category: { type: FFT.multiselect, options: pLabelOptions("investment_category") },
  purchase_form: { type: FFT.multiselect, options: pLabelOptions("purchase_form") },
  industrial_area: { type: FFT.range, unit: "area" },
  tenant_structure: { type: FFT.multiselect, options: pLabelOptions("tenant_structure") },
  walt: { type: FFT.multiselect, options: pLabelOptions("walt") },
  single_rooms_quota: { type: FFT.range },
  occupancy_rate: { type: FFT.range },
  conservation_areas: { type: FFT.yesNo },
  number_of_parking_spaces: { type: FFT.range },
  number_of_apartments: { type: FFT.range },
  number_of_commercials: { type: FFT.range },
  bgf: { type: FFT.range, unit: "area" },
  recommended_use_types: { type: FFT.multiselect, options: pLabelOptions("recommended_use_types") },
  short_term_constructible: { type: FFT.yesNo },
  site_development_type: { type: FFT.multiselect, options: pLabelOptions("site_development_type") },
  building_permission: { type: FFT.yesNo },
  preliminary_enquiry: { type: FFT.yesNo },
  energy_efficiency_class: { type: FFT.multiselect, options: pLabelOptions("energy_efficiency_class") },
  number_of_units: { type: FFT.range },
  demolition: { type: FFT.yesNo },
  gfz: { type: FFT.range },
  grz: { type: FFT.range },
}

export default FORM_FIELDS

import { InjectionKey, Ref } from "vue"

export type ConnectionFormat =
  | "IS24"
  | "OPENIMMO"
  | "XML2U"
  | "PRIAN"
  | "WSJ"
  | "IDX"
  | "REALOGY"
  | "FACEBOOK"
  | "INSTAGRAM"
  | "MLS"
  | "CHRISTIES"
  | "FEED"
  | "IDEALISTA"

export enum IdealistaScope {
  idealista = "idealista",
  microsite = "microsite",
}

export enum IdealistaAddressVisibility {
  full = "full",
  street = "street",
  hidden = "hidden",
}

export enum IdealistaLocationPrecision {
  exact = "exact",
  moved = "moved",
}

export interface Connection {
  id: number
  format: ConnectionFormat
  name: string
  parentConnectionId?: number
  vendorNr?: string
  host?: string
  port?: string
  username?: string
  password?: string
  directory?: string
  locale?: string
  locales?: string[]
  customPort?: string
  feedFormat?: string
  xmlFeedUrl?: string
  rotationCount?: number
  remoteUrl?: Boolean
  importEmailUnwanted?: Boolean
  preconfigured?: Boolean
  popularWebsite: Boolean
  alwaysDelete?: Boolean
  createPublishingActivities?: Boolean
  folderId?: number
  photoUrl?: string
  accountUrl?: string
  accessBrokerIds?: number[]
  accessDepartmentIds?: number[]
  createdAt: Date
  updatedAt: Date
  idealistaFeedKey?: string
  idealistaScope?: IdealistaScope
  idealistaAddressVisibility?: IdealistaAddressVisibility
  idealistaLocationPrecision?: IdealistaLocationPrecision
  is24OtoaAccessBrokerIds?: number[]
  is24OtoaAccessDepartmentIds?: number[]
  fieldOverrides?: FieldOverride[]
  apiDefinition?: ExternalApiProperties
  disableWatermarking?: Boolean
}

export type ExternalApiProperties = Record<string, ExternalApiFieldDefinition>

export type ExternalApiFieldDefinition = {
  name: string
  title: string
  type?: "string" | "number" | "boolean" | "object" | "array"
  description?: string
  pattern?: string
  properties?: ExternalApiProperties
  enum?: string[]
  minimum?: number
  maximum?: number
  minItems?: number
  maxItems?: number
  deprecated?: boolean
}

export const ExternalApiInjectionKey = Symbol("ExternalApiInjectionKey") as InjectionKey<Ref<ExternalApiProperties>>

export type OverrideOption = ExternalApiFieldDefinition & {
  key: string
  path: string
  disabled?: boolean
  children?: OverrideOption[]
}

export const OverrideOptionsInjectionKey = Symbol("ExternalApiInjectionKey") as InjectionKey<Ref<OverrideOption[]>>

export interface FieldOverride {
  field: string
  formula: string
  type?: ExternalApiFieldDefinition["type"]
}

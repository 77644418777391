<template>
  <nice-select
    v-if="!loading"
    filterable
    grouped
    clearable
    :modelValue="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
    :placeholder="placeholder ?? t('detailView.snippetPh')"
    children-key="snippets"
    :options="options"
    v-bind="$attrs"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import useCore from "@/plugins/use-core"
const { t } = useI18n()
const { graphql } = useCore()

const emit = defineEmits(["update:modelValue", "change"])

export type SnippetSelectProps = {
  modelValue?: number
  placeholder?: string
}

const { modelValue = null, placeholder } = defineProps<SnippetSelectProps>()

const options = ref([])
const loading = ref(false)

const fetchData = async () => {
  loading.value = true
  const { shop } = await graphql(
    `
      query snippetCategories {
        shop {
          snippetCategories {
            id
            name
            snippets(forCurrentBroker: true) {
              id
              name
            }
          }
        }
      }
    `,
    undefined,
    true
  )
  options.value = shop.snippetCategories
  loading.value = false
}

onMounted(() => {
  fetchData()
})
</script>

<template>
  <nice-popover
    ref="popover"
    placement="bottom"
    :show-arrow="false"
    trigger="click"
    :width="width"
    transition="none"
    @after-enter="$refs.searchqueryRef?.focus()"
    @after-leave="q = ''"
    v-model:visible="visible"
    :offset="offset"
  >
    <template #reference>
      <slot name="reference" v-bind="{ selectLabel, open, close, isVisible: visible }">
        <button class="tableActions-action" :class="{ active: active }">
          {{ selectLabel }}
          <fa-icon name="angle-down" />
        </button>
      </slot>
    </template>
    <header v-show="showHeader" class="np-header">
      <h5 class="np-title">{{ title || $t("selectPopover.placeholder") }}</h5>
      <div class="np-searchWrap" v-if="searchable">
        <input
          ref="searchqueryRef"
          type="search"
          v-model="q"
          class="np-search"
          :placeholder="$t('selectPopover.placeholder')"
        />
      </div>
    </header>
    <ul class="np-list">
      <li
        class="np-listItem"
        :class="{ 'np-listItem--selected': isSelected(item) }"
        v-for="(item, idx) in filteredItems"
        :key="idx"
        @click="clicked(item)"
      >
        <slot name="item" :item="item" :isSelected="isSelected(item)">
          <span v-if="showDots" class="np-listItem-colorIndicator" :style="{ 'background-color': item.color }"></span>
          <span class="np-listItem-inner">{{ item[labelKey] }}</span>
          <fa-icon name="check" class="np-listItem-check" v-if="isSelected(item)" />
        </slot>
      </li>
    </ul>
  </nice-popover>
</template>

<script>
export default {
  emits: ["update:selected", "click"],
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    emptyTitle: {
      type: String,
      required: false,
    },
    emptyOption: {
      type: String,
    },
    selected: {},
    defaultLabel: {
      type: String,
    },
    labelKey: {
      type: String,
      default: "name",
    },
    showSelectedIndicator: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    selectedFirst: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 300,
    },
    showDots: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    offset: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      q: "",
      visible: false,
      sortedItems: undefined,
    }
  },
  watch: {
    visible: {
      handler: function (newValue) {
        if (!this.selectedFirst || !newValue) return

        const otherOptions = this.items.filter(item => !this.selectedArray.includes(item.id))
        this.sortedItems = [this.emptyItem]
          .concat(this.selectedItems)
          .concat(otherOptions)
          .filter(i => i && i[this.labelKey].toLowerCase().includes(this.q.toLowerCase()))
      },
    },
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    clicked(item) {
      this.$emit("click", item)
      if (this.autoclose || !item.id) this.visible = false

      if (this.multiple) {
        if (!item.id) {
          this.$emit("update:selected", [])
        } else if (this.selected.includes(item.id)) {
          this.$emit(
            "update:selected",
            this.selected.filter(id => id != item.id)
          )
        } else {
          this.$emit("update:selected", this.selected.concat(item.id))
        }
      }
    },
    isSelected(item) {
      return (
        this.showSelectedIndicator &&
        (item.id == null ? this.selectedArray.length == 0 : this.selectedArray.includes(item.id))
      )
    },
  },
  computed: {
    selectedItems() {
      return this.items.filter(item => this.selectedArray.includes(item.id))
    },
    selectedArray() {
      return Array.isArray(this.selected) ? this.selected : [this.selected].filter(o => o)
    },
    emptyItem() {
      if (this.emptyOption)
        return {
          id: null,
          [this.labelKey]: this.emptyOption,
        }
    },
    filteredItems() {
      return (this.sortedItems || [this.emptyItem].concat(this.items)).filter(
        i => i && i[this.labelKey].toLowerCase().includes(this.q.toLowerCase())
      )
    },
    selectLabel() {
      try {
        const length = this.selectedArray.length
        let label = !length ? this.defaultEmptyTitle : this.selectedItems[0][this.labelKey]
        if (length > 1) label = `${label} +${length - 1}`
        return label
      } catch (e) {
        console.error(e)
        return this.defaultEmptyTitle
      }
    },
    defaultEmptyTitle() {
      return this.emptyTitle || this.emptyOption
    },
    multiple() {
      return Array.isArray(this.selected)
    },
    autoclose() {
      return !this.multiple
    },
  },
}
</script>

<style scoped>
.np-header {
  border-bottom: 1px solid #e9e9e9;
  padding: 12px 8px;
}
.np-title {
  text-align: center;
  font-size: 1rem;
}
.np-searchWrap {
  padding: 12px 4px 0;
}
.np-search {
  border: none;
  background: #e9e9e9;
  border-radius: 4px;
  width: 100%;
  padding: 6px;
}

.np-list {
  margin: 6px 0;
  padding: 0;
  list-style: none;
  max-height: 50vh;
  overflow: auto;
}
.np-listItem {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.np-listItem:hover {
  background: #e9e9e9;
}
.np-listItem-colorIndicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 8px;
  opacity: 0.85;
}
.np-listItem-inner {
  flex: 1;
}
.np-listItem-check {
  margin-left: 0.5rem;
  color: var(--primary);
}
.np-listItem--selected {
  color: var(--primary);
}
</style>

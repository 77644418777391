import useCore from "@/plugins/use-core"
import { getInboxNavigationItems } from "./inbox.utils"
import { useI18n } from "vue-i18n"
import { computed } from "vue"

export const getInboxMenu = ({ inboxId, inboxBrokerPath, smartFolders, inboxCounters, db, t }) => {
  const {
    folderTree,
    customFolderRoots,
    folderMap: _,
  } = db.broker.inboxMenuItemsByBroker[inboxId] || db.broker.inboxMenuItemsByBroker["default"]
  const inboxItems = getInboxNavigationItems({
    folderMap: folderTree,
    inboxCounters,
    customFolderRoots,
    smartFolders,
    numBrokers: db.shopData.brokers.length,
    inboxBrokerPath,
    t,
  })

  return inboxItems
}

export const useInboxMenu = ({ inboxId, inboxBrokerPath, smartFolders, inboxCounters }) => {
  const { db } = useCore()
  const { t } = useI18n()

  const inboxMenuItems = computed(() => getInboxMenu({ inboxId, inboxBrokerPath, smartFolders, inboxCounters, db, t }))

  return { inboxMenuItems }
}

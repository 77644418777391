<template>
  <nice-label v-if="category" :label="category.name" :color="category.color" :size="size" />
</template>

<script setup lang="ts">
import useCore from "@/plugins/use-core"
import { NiceLabelProps } from "@nice-ui/components/NiceLabel.vue"
import { computed } from "vue"

const { db } = useCore()
export type DbLabelProps = {
  collection: string
  resourceId: number
  size: NiceLabelProps["size"]
}

const { collection, resourceId, size } = defineProps<DbLabelProps>()

const category = computed(() => {
  return db.shopData[collection].find(o => o.id == resourceId)
})
</script>

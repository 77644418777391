<template>
  <div class="w-64 h-36 black flex border-2 border-gray" :style="`background-color: ${watermarkContrast};`">
    <div
      :class="`w-64 h-36 flex items-${cssPosition.vertical} justify-${cssPosition.horizontal}`"
      v-if="cssPosition != null"
    >
      <img :src="url" :style="`opacity: ${opacity}; width: ${watermarkSize.width}; height: ${watermarkSize.height};`" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, computed } from "vue"
import { previewPositions, previewCanvas } from "./WatermarkPreview.constants"

export type WatermarkPreviewProps = {
  url: string
  opacity: number
  scale: number
  position: string
  width: number
  height: number
}
const props = defineProps<WatermarkPreviewProps>()

const watermarkWidth = ref(0)
const watermarkHeight = ref(0)
const watermarkContrast = ref("lightgray")

watch(
  () => props.url,
  () => {
    loadImage()
  }
)

function loadImage() {
  const watermarkImage = new Image()
  watermarkImage.onload = () => {
    watermarkWidth.value = watermarkImage.width
    watermarkHeight.value = watermarkImage.height
  }
  watermarkImage.src = props.url
}
const watermarkSize = computed(() => {
  const heightDiff = watermarkHeight.value - previewCanvas.height
  const widthDiff = watermarkWidth.value - previewCanvas.width
  const largerScale = (
    heightDiff > 0 || widthDiff > 0 ? heightDiff > widthDiff : watermarkHeight.value > watermarkWidth.value
  )
    ? "height"
    : "width"
  const size = Math.floor(previewCanvas[largerScale] * props.scale) - 1

  return {
    width: largerScale === "width" ? `${size}px` : "auto",
    height: largerScale === "height" ? `${size}px` : "auto",
  }
})
const cssPosition = computed(() => {
  return previewPositions[props.position] || null
})

onMounted(() => {
  loadImage()
})
</script>

<style></style>

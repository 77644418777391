<template>
  <form-dialog
    :visible="visible"
    :name="formTitle"
    :id="edit ? edit.id : undefined"
    append-to-body
    @close="$emit('update:visible', false)"
    :saving="submitting"
    @submit="submit"
  >
    <form-section v-if="!edit">
      <form-row
        :title="$t('detailView.prop')"
        v-if="initialRs.internalName !== 'associate' && !initialRs.projectId && !initialRs.propertyId"
      >
        <property-select v-model="rs.propertyId" />
      </form-row>
      <form-row :title="$t('detailView.client')" v-if="!initialRs.relatedClientId">
        <client-select v-model="rs.relatedClientId" />
      </form-row>
      <form-row :title="$t('detailView.relationship.type')">
        <p v-if="initialRs.name">{{ initialRs.name }}</p>
        <nice-input size="small" v-else v-model="rs.name" />
      </form-row>
      <form-row
        :title="$t('detailView.relationship.transferClient')"
        :hint="$t('detailView.relationship.transferClientHint')"
        v-if="initialRs.internalName == 'associate'"
      >
        <nice-checkbox v-model="rs.transferClientData" />
      </form-row>
    </form-section>
    <form-section v-else>
      <form-row :title="$t('detailView.relationship.typeOpt')">
        <nice-input size="small" v-model="edit.name" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script>
const RS_QUERY = `relationship {
  id name internalName
  relatedClient { id name }
  project { id name }
  property { id name }
}`

export default {
  props: {
    initialRs: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      submitting: false,
      rs: this.$util.clone(this.initialRs),
    }
  },
  methods: {
    submit() {
      if (this.edit) {
        this.submitting = true
        this.$api
          .mutation("updateRelationship", { id: this.edit.id, payload: { name: this.edit.name } })
          .then(() => {
            this.$emit("create", this.edit)
            this.$emit("update:visible", false)
            App.flashy(this.$t("detailView.relationship.editSuccess"))
          })
          .finally(_ => {
            this.submitting = false
          })
      } else {
        this.submitting = true
        this.$api
          .mutation("createRelationship", { payload: this.rs }, RS_QUERY)
          .then(({ relationship }) => {
            this.$emit("update:visible", false)
            this.$emit("create", relationship)
            App.flashy(this.$t("detailView.relationship.createSuccess"))
            this.rs = this.$util.clone(this.initialRs)
          })
          .catch(this.$axios.handleError)
          .finally(_ => {
            this.submitting = false
          })
      }
    },
  },
  computed: {
    formTitle() {
      const mapping = {
        owner: this.$t("detailView.relationship.owner"),
        partner: this.$t("detailView.relationship.partner"),
        associate: this.$t("detailView.relationship.associate"),
      }

      return mapping[this.initialRs.internalName] || this.$t("detailView.relationship.title")
    },
  },
}
</script>

<script lang="ts" setup>
import { useLastPublishedPortal } from "@/composables/use-last-published-portal"
import eventBus from "@/config/event-bus"
import { useIntegration } from "@/integrations/use-integration"
import { watch } from "vue"

const props = withDefaults(
  defineProps<{
    propertyId: number
    scoutPropertyId?: number
    conversationId?: string
    hasCloseButton?: boolean
    isDisabled: boolean
    type: "deal" | "object"
  }>(),
  {
    hasCloseButton: true,
  }
)

const { isEnabled } = useIntegration("extension-scout-messenger-ps")
const { lastPublishedPortal, fetchData } = useLastPublishedPortal(props.propertyId)

watch(
  () => props.scoutPropertyId,
  () => {
    fetchData()
  }
)

const openMessengerQuickview = () => {
  eventBus.$emit("quick-view", {
    type: "scoutMessenger",
    mode: "edit",
    params: {
      hasCloseButton: true,
      propertyId: props.propertyId,
      scoutPropertyId: props.scoutPropertyId,
      conversationId: props.conversationId,
    },
  })
}
</script>
<template>
  <tooltip
    v-if="isDisabled"
    :content="$t(`integrations.extension-scout-messenger.action.disabled.tooltip.${props.type}`)"
  >
    <a class="cursor-not-allowed !text-neutral-400">
      <fa-icon name="comment-alt-dots" />
    </a>
  </tooltip>
  <tooltip v-else-if="isEnabled && lastPublishedPortal" :content="$t('client.sendMessage')">
    <a @click.prevent="openMessengerQuickview()">
      <fa-icon name="comment-alt-dots" />
    </a>
  </tooltip>
</template>

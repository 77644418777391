<template>
  <div class="w-100">
    <nice-input
      ref="newCityInput"
      v-model="inputValue"
      :placeholder="$t(placeholder)"
      size="small"
      class="mb-2"
      :disabled="disabled"
    />
    <el-tag
      :key="tag"
      v-for="tag in modelValue"
      closable
      type="info"
      size="small"
      class="mr-2 mb-1"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>
  </div>
</template>

<script>
import { loadGoogleAPI } from "@/core/3rdparty/GoogleAPI"
export default {
  async setup() {
    const places = await loadGoogleAPI("places")
    return { places }
  },
  props: {
    modelValue: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: "client.queries.form.cityPh",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sellsInternationally: {
      type: Boolean,
      default: false,
    },
    internationalCountry: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      inputVisible: false,
      inputValue: "",
    }
  },
  methods: {
    handleClose(city) {
      const index = this.modelValue.indexOf(city)

      if (index >= 0) {
        const newValue = [...this.modelValue]
        newValue.splice(index, 1) // splice modifies in place, the return value is the removed elements
        this.$emit("update:modelValue", newValue)
      }
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      let value = this.modelValue
      if (!value) {
        value = []
      }
      if (inputValue && !value.includes(inputValue)) {
        value.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ""
      this.$emit("update:modelValue", value)
    },
  },
  computed: {
    googleCountryRestriction() {
      const countryMapping = {
        swiss: "ch",
        austria: "at",
        spain: "es",
        hungary: "hu",
      }
      return this.sellsInternationally
        ? null
        : this.internationalCountry
        ? countryMapping[this.internationalCountry]
        : "de"
    },
  },
  mounted() {
    const input = this.$refs.newCityInput.$refs.input.$refs.input
    this.autocomplete = new this.places.Autocomplete(input, {
      types: ["(cities)"],
      componentRestrictions: !!this.googleCountryRestriction ? { country: this.googleCountryRestriction } : undefined,
    })
    this.autocomplete.addListener("place_changed", event => {
      const place = this.autocomplete.getPlace()
      if (!place.place_id) return // user wrote random shit
      this.inputValue = place.name
      this.handleInputConfirm()
    })
  },
}
</script>

<style>
/* puts the google places autocomplete dropdown results above the el-dialog modal 2001 zindex. */
.pac-container {
  z-index: 12002 !important;
}
</style>

import useCore from "@/plugins/use-core"
import { uniq } from "lodash"
import { LocaleKey, LOCALES } from "./locales"
import { useI18n } from "vue-i18n"

export const useBrokerLocale = () => {
  const { db } = useCore()
  return db.broker.locale as LocaleKey
}

export const useAvailableShopLocales = () => {
  const { db } = useCore()
  return uniq([db.shopData.language].concat(db.shopData.supportedClientLocales || []).filter(Boolean)) as LocaleKey[]
}

export const useTranslatedLocales = () => {
  const { t } = useI18n()
  return Object.keys(LOCALES).reduce((acc, locale) => {
    acc[locale] = t(`locales.${locale}`)
    return acc
  }, {} as Record<LocaleKey, string>)
}

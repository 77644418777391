<template>
  <form-section>
    <form-row v-if="item.format == 'IS24' && failed.includes(item.id)">
      <div class="flex justify-between items-center w-full">
        <a class="block text-sm ml-auto" :href="`/admin/immoscout/renew?connection_id=${item.id}`">
          {{ $t("admin.connections.renewImmoGermanyConnect") }}
        </a>
      </div>
    </form-row>
    <form-row :title="$t('admin.connections.name')" :hint="$t('admin.connections.nameDesc')">
      <nice-input size="small" :placeholder="$t('admin.connections.name')" v-model="item.name" />
    </form-row>
    <form-row
      v-if="$db.featureActive('portal_folders')"
      :title="$t('admin.connections.parentFolder')"
      :hint="$t('admin.connections.folderDesc')"
    >
      <db-select collection="folders" v-model="item.folderId" />
    </form-row>
    <form-row :title="$t('admin.connections.accessBrokers')" :hint="$t('admin.connections.accessBrokersDesc')">
      <db-select collection="activeBrokers" multiple v-model="item.accessBrokerIds" />
    </form-row>
    <form-row :title="$t('admin.connections.accessDepartments')" :hint="$t('admin.connections.accessDepartmentsDesc')">
      <db-select collection="departments" multiple v-model="item.accessDepartmentIds" />
    </form-row>
    <form-row
      :title="$t('admin.connections.alwaysDelete')"
      :hint="$t('admin.connections.alwaysDeleteDesc')"
      v-if="$db.featureActive('is24_always_delete') && item.format == 'IS24'"
    >
      <nice-switch v-model="item.alwaysDelete" />
    </form-row>
    <template v-if="isFtp">
      <form-row :title="$t('admin.connections.providerNumber')" :hint="$t('admin.connections.providerNumberDesc')">
        <nice-input size="small" placeholder="12345678" v-model="item.vendorNr" />
      </form-row>
      <form-row :title="$t('admin.connections.host')">
        <nice-input size="small" placeholder="ftp.muster-portal.de" v-model="item.host" />
      </form-row>
      <form-row :title="$t('admin.connections.port')">
        <nice-radio-group
          v-model="item.port"
          :options="[
            { id: '21', name: '21 (FTP)' },
            { id: '22', name: '22 (SFTP)' },
            { id: '990', name: '990 (FTPS)' },
          ]"
        />
      </form-row>
      <form-row :title="$t('admin.connections.ownPort')" :hint="$t('admin.connections.setPortCondition')">
        <nice-input size="small" type="number" :placeholder="$t('admin.connections.port')" v-model="item.customPort" />
      </form-row>
      <form-row :title="$t('admin.connections.userName')">
        <nice-input size="small" :placeholder="$t('admin.connections.userName')" v-model="item.username" />
      </form-row>
      <form-row :title="$t('admin.connections.password')">
        <nice-input
          size="small"
          type="password"
          :placeholder="$t('admin.connections.password')"
          v-model="item.password"
        />
      </form-row>
      <form-row :title="$t('admin.connections.directory')" v-if="item.format == 'OPENIMMO'">
        <nice-input size="small" placeholder="/" v-model="item.directory" />
      </form-row>
      <form-row v-if="item.format == 'OPENIMMO'" :title="$t('admin.connections.languages')">
        <nice-select multiple v-model="item.locales" :options="localeOptions" />
      </form-row>
      <form-row v-else :title="$t('admin.connections.language')">
        <nice-select v-model="item.locale" :options="localeOptions" />
      </form-row>
      <form-row
        :title="$t('admin.connections.sendImageAsUrls')"
        :hint="$t('admin.connections.sendImageAsUrlsDesc')"
        v-if="item.format == 'OPENIMMO'"
      >
        <nice-switch v-model="item.remoteUrl" />
      </form-row>
      <form-row
        :title="$t('admin.connections.turnOffImport')"
        :hint="$t('admin.connections.turnOffImportDesc')"
        v-if="item.format == 'OPENIMMO'"
      >
        <nice-switch v-model="item.importEmailUnwanted" />
      </form-row>
    </template>
    <template v-if="item.format === 'IDEALISTA'">
      <idealista-settings :item="item" />
    </template>
    <form-row
      v-if="item.id && (item.format === 'IDEALISTA' || item.feedFormat === 'kyero')"
      :title="$t('admin.connections.fieldOverrides')"
    >
      <router-link class="btn btn-primary" :to="{ name: 'admin.connections.overrides', params: { id: `${item.id}` } }">
        {{ t("admin.connections.overrideButton") }}
      </router-link>
    </form-row>
    <form-row
      :title="$t('admin.connections.createPublishingActivities')"
      :hint="$t('admin.connections.createPublishingActivitiesHint')"
    >
      <nice-switch v-model="item.createPublishingActivities" />
    </form-row>
    <form-row v-if="$db.shopData.watermarkOnPortals" :title="$t('admin.connections.disableWatermarking')">
      <nice-switch v-model="item.disableWatermarking" color="danger" />
    </form-row>
    <template v-if="item.format === 'IS24'">
      <scout-settings :item="item" />
    </template>
  </form-section>
</template>

<script lang="ts">
import { Connection } from "@/interfaces"
import { defineAsyncComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"

export default {
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
  components: {
    IdealistaSettings: defineAsyncComponent(() => import("@/components/connections/IdealistaSettings.vue")),
    ScoutSettings: defineAsyncComponent(() => import("@/components/connections/ScoutSettings.vue")),
  },
  props: {
    item: {
      type: Object as PropType<Connection>,
      required: true,
    },
    failed: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isFtp(): boolean {
      if (!this.item) return false

      return (
        !["IS24", "FACEBOOK", "INSTAGRAM", "IDEALISTA"].includes(this.item.format) &&
        !this.item.xmlFeedUrl &&
        !this.item.preconfigured
      )
    },
    localeOptions(): Record<string, any> {
      return {
        "de-DE": this.t("admin.connections.german"),
        "en-US": this.t("admin.connections.english"),
        "fr-FR": this.t("admin.connections.french"),
        "es-ES": this.t("admin.connections.spanish"),
        "it-IT": this.t("admin.connections.italian"),
      }
    },
  },
}
</script>

<template>
  <nice-select
    v-if="$db.shopData.useStaticDropdownForCitySelect"
    filterable
    multiple
    allow-create
    default-first-option
    v-model="record.cities"
    :placeholder="$t('client.queries.form.cityPh')"
    :options="$db.shopData.cities.map(city => ({ id: city, name: city }))"
  ></nice-select>
  <CitySelect
    v-else
    v-model="record.cities"
    :sells-internationally="$db.shopData.sellsInternationally"
    :international-country="$db.shopData.internationalCountry"
  />
</template>

<script>
import { defineComponent } from "vue"
import CitySelect from "@/components/CitySelect"

export default defineComponent({
  props: ["record", "field"],
  components: {
    CitySelect,
  },
})
</script>

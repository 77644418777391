<template>
  <div class="app-panel">
    <page-header>
      <template v-slot:leftMenu>
        <a href="#" @click.prevent="close" class="pane-button">
          <fa-icon name="times" />
        </a>
      </template>
      <template v-slot:rightButtons>
        <a
          href="#"
          data-toggle="error-popover"
          @click.prevent="submit"
          class="ml-2 pane-button"
          :class="{ disabled: submitting }"
        >
          <span v-if="submitting" class="spinner spinner-sm mr-2"></span>
          {{ $t("panes.letterForm.create") }}
        </a>
      </template>
    </page-header>

    <div class="app-panel-body">
      <form id="letter-form" @submit.prevent="submit">
        <form-row :title="$t('panes.letterForm.template')">
          <nice-select
            v-model="params.letterTemplateId"
            :grouped="isGrouped"
            :options="isGrouped ? groupedLetterTemplates : letterTemplates"
          />
        </form-row>

        <form-row :title="$t('panes.letterForm.addContact')">
          <p v-if="massClientIds" class="text-primary">
            <strong>{{ massClientIds.length }}</strong>
            {{ $t("panes.letterForm.selected") }}
          </p>
          <client-select v-else v-model="params.clientIds" multiple />
        </form-row>

        <form-row :title="$t('panes.letterForm.addProperty')">
          <property-select v-model="params.propertyId" variants />
        </form-row>

        <form-row :title="$t('panes.letterForm.addProjects')">
          <project-select v-model="params.projectId" />
        </form-row>

        <form-row :title="$t('panes.letterForm.subject')">
          <input type="text" class="form-control" v-model="params.subject" />
        </form-row>

        <form-row v-if="teams.length > 0" :title="$t('panes.letterForm.tenant')">
          <nice-select v-model="params.teamId" :options="teams" :placeholder="$t('panes.letterForm.tenant')" />
        </form-row>

        <form-row :title="$t('panes.letterForm.saveAsActivity')" :hint="$t('panes.letterForm.saveAsActivityHint')">
          <nice-checkbox v-model="saveAsActivity" />
        </form-row>

        <form-row :title="$t('panes.letterForm.activityDate')" v-if="saveAsActivity">
          <nice-date-picker
            v-model="activityDate"
            type="datetime"
            format="dd.MM.yyyy HH:mm"
            class="w-100"
            :placeholder="$t('panes.letterForm.activityDatePh')"
            :clearable="false"
          />
        </form-row>

        <div class="form-group">
          <label class="mb-1 block">{{ $t("panes.letterForm.note") }}</label>
          <nice-textarea v-model="params.note"></nice-textarea>
        </div>

        <p v-if="taskId" class="text-gray-600 text-sm">
          <fa-icon name="check" class="text-green-600 mr-1" />
          <span>{{ $t("panes.letterForm.variableAccess") }}</span>
        </p>
      </form>
    </div>
    <nice-dialog
      :title="$t('panes.letterForm.creatingLetter')"
      v-model="submitModal.visible"
      append-to-body
      width="450px"
    >
      <div class="flex justify-center text-center">
        <div>
          <nice-progress type="circle" :percentage="submitModalPercentage" :status="submitModalStatus" />
          <p class="mt-3">{{ progressLabel }}</p>
        </div>
      </div>
    </nice-dialog>
  </div>
</template>

<script>
import saveStateMixin from "@/mixins/save-state-mixin"

export default {
  props: ["source", "massAction"],
  mixins: [saveStateMixin],

  data() {
    return {
      submitting: false,
      params: {
        letterTemplateId: null,
        subject: "",
        note: "",
        clientIds: (this.source && this.source.clientIds) || [],
        propertyId: (this.source && this.source.propertyIds && this.source.propertyIds[0]) || null,
        projectId: (this.source && this.source.projectIds && this.source.projectIds[0]) || null,
        teamId: null,
      },
      activityDate: null,
      saveAsActivity: true,
      submitModal: {
        visible: false,
        percentage: 0,
        error: null,
        clientNames: [],
      },
    }
  },
  methods: {
    submit() {
      this.submitting = true

      this.subscribe()

      this.$axios
        .post("/services/letters", this.payload)
        .then(_ => {
          this.submitModal.visible = true
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.submitting = false
        })
    },
    close() {
      this.$emit("close")
    },
    reset() {
      this.submitModal.clientNames = []
      if (this.subscriptionId) {
        this.$pusher.off(`letter:${this.subscriptionId}:progress`)
        this.$pusher.off(`letter:${this.subscriptionId}:complete`)
        this.$pusher.off(`letter:${this.subscriptionId}:error`)
      }
    },
    subscribe() {
      this.reset()
      this.subscriptionId = Date.now().toString()
      this.$pusher.on(`letter:${this.subscriptionId}:progress`, ({ clientName }) => {
        this.submitModal.clientNames.push(clientName)
      })
      this.$pusher.on(`letter:${this.subscriptionId}:complete`, ({ url }) => {
        window.location.href = url
        App.flashy(this.$t("panes.letterForm.createSuccess"))
        this.submitModal.visible = false
        this.$emit("close")
      })
      this.$pusher.on(`letter:${this.subscriptionId}:error`, ({ message }) => {
        this.submitModal.error = message
      })
    },
  },
  computed: {
    saveStateConfig() {
      return {
        cacheKey: `letter-form`,
        saveProperties: ["saveAsActivity"],
      }
    },
    massClientIds() {
      return this.massAction ? this.massAction.clientIds : null
    },
    taskId() {
      return this.source ? this.source.taskId : null
    },
    payload() {
      return {
        letter: {
          subscription_id: this.subscriptionId,
          letter_template_id: this.params.letterTemplateId,
          client_ids: (Array.isArray(this.massClientIds) && this.massClientIds) || this.params.clientIds,
          client_filter: (!Array.isArray(this.massClientIds) && this.massClientIds) || undefined,
          subject: this.params.subject,
          note: this.params.note,
          property_id: this.params.propertyId,
          project_id: this.params.projectId,
          team_id: this.params.teamId,
          task_id: this.taskId,
          save_as_activity: this.saveAsActivity,
          activity_date: this.activityDate,
        },
      }
    },
    submitModalPercentage() {
      return parseInt(
        (this.submitModal.clientNames.length / (this.massClientIds || this.params.clientIds).length) * 100
      )
    },
    submitModalStatus() {
      if (this.submitModal.error) return "exception"
      if (this.submitModalPercentage >= 100) return "success"
    },
    progressLabel() {
      if (this.submitModalStatus == "exception") return this.submitModal.error
      if (this.submitModalStatus == "success") return this.$t("panes.letterForm.downloadSuccess")
      const name = this.submitModal.clientNames[this.submitModal.clientNames.length - 1]
      if (name) return this.$t("panes.letterForm.createFor", { name: name })
      return this.$t("panes.letterForm.createPrep")
    },
    teams() {
      if (this.$db.broker.accessTeamIds && this.$db.broker.accessTeamIds.length > 0) {
        return this.$db.shopData.teams.filter(t => this.$db.broker.accessTeamIds.includes(t.id))
      }
      return []
    },
    letterTemplates() {
      return this.$db.shopData.letterTemplates.filter(lt => lt.category != "property_report")
    },
    isGrouped() {
      return this.letterTemplates.some(n => n.noteTypeId != null)
    },
    groupedLetterTemplates() {
      const newCategories = [
        ...this.$db.shopData.letterCategories,
        { id: null, name: this.$t("panes.letterForm.uncategorized") },
      ]

      return newCategories.map(lg => ({
        id: lg.id,
        name: lg.name,
        children: this.letterTemplates.filter(lc => lc.noteTypeId == lg.id),
      }))
    },
  },
}
</script>

<template>
  <widget-container :widget="widget" :config="config" :loading="chartDataLoading" class="p-2" :gradient="false">
    <table-popover
      :data="detailData"
      :fetch="fetchDetailData"
      :per="detailPer"
      :columns="detailColumns"
      :on-state-change="onChangeModalState"
    />
    <chartist
      :key="widget.key"
      class="h-full"
      :ratio="chartRatio"
      :type="chartTypeCapital"
      :data="chartData"
      :options="chartOptions"
      :event-handlers="eventHandlers"
      v-if="['line', 'bar', 'pie'].includes(chartType) && chartDataAvailable"
    />
    <tiles
      v-else-if="['tab'].includes(chartType) && chartDataAvailable"
      :key="`${widget.key}-tile`"
      :data="chartData"
      :options="chartOptions"
      :click-handler="onClick"
    />
    <span class="m-3" v-else>{{ $t("dashboard.charts.noData") }}</span>
  </widget-container>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import ChartistAxistitle from "chartist-plugin-axistitle"
import WidgetContainer from "./WidgetContainer.vue"
import Filters from "@/config/filters"
import Tiles from "./Tiles.vue"
import TablePopover from "@/components/TablePopover.vue"
import { ChartistLegend, ChartistTooltip } from "@/utils/chartist-plugins"
import { buildQuery, resolvePlaceholders } from "@/mixins/elastic-filters"

import { getPropertyColumns } from "@/utils/get-property-columns"
import { getActivityColumns } from "@/utils/get-activity-columns"
import { getClientColumns } from "@/utils/get-client-columns"
import { getPluralizedName } from "@/utils/get-pluralized-name"

const COLUMNS_MAP = {
  client: getClientColumns,
  property: getPropertyColumns,
  activity: getActivityColumns,
}

const PS_COLORS = ["#B50109", "#790A4D", "#3A1AA5", "#004AF9", "#007FE0", "#00AFC0", "#34D057", "#74DB4E", "#EBD877"]

const AGGREGATE_ENDPOINTS: Record<string, string> = {
  property: "/api/v1/units/aggregate",
  activity: "/api/v1/activities/aggregate",
  client: "/api/v1/contacts/aggregate",
}

const DETAIL_ENDPOINTS: Record<string, string> = {
  property: "/api/v1/units/search",
  activity: "/api/v1/activities/search",
  client: "/api/v1/contacts/search",
}

const DEFAULT_DETAIL_COLUMNS: Record<string, any> = {
  property: [
    {
      key: "title",
      name: "title",
      width: 400,
      visible: true,
      render: {
        component: "router-link",
        props: (_, data) => ({ to: `/portfolio/properties/${data.id}`, class: "link" }),
      },
    },
  ],
  activity: [
    {
      key: "title",
      name: "title",
      width: 400,
      visible: true,
      render: {
        component: "router-link",
        props: (_, data) => ({ to: `?${data.conversation_type}=${data.activatable_id}`, class: "link" }),
      },
    },
  ],
  client: [
    {
      key: "name",
      name: "name",
      width: 400,
      visible: true,
      render: {
        component: "router-link",
        props: (_, data) => ({ to: `/contacts/clients/${data.id}`, class: "link" }),
      },
    },
  ],
}

const getFormField = (it: any, key: string) => {
  return COLUMNS_MAP[it.chartConfig.entity](it.$db, it.$t, it.$tm).find(c => c.value === key)
}

const getLabel = (it: any, key: string) => {
  const field = getFormField(it, key)
  return field?.isCustomField
    ? field?.label
    : it.$t(`${it.pluralizedEntity}.formFields.${field?.value.replace(/\.raw|\.keyword|\.id/, "") || key}`)
}

const getAxisName = (it: any, prop: any) => {
  if (!prop) return it.$t(`dashboard.charts.docCount`)
  if (prop.type === "histogram") {
    return it.$t(`dashboard.charts.axis1Interval.options.${prop.interval}`)
  }
  return it.$t(`dashboard.charts.axis0Type.options.${prop.type}`)
}

const getMetricName = (it: any, prop: any) => {
  const method = getAxisName(it, prop)
  if (!prop) return method
  const name = getLabel(it, prop.name)
  if (prop.type === "terms") {
    return name
  }
  return [method, name].join(" ")
}

const REMOTE_DATA_ENDPOINTS = {
  property: "/portfolio/properties?property_ids=",
  client: "/contacts/clients?client_ids=",
}

const fetchDbOptions = (it: any, formField: any, values: any[]) => {
  const data =
    typeof it.$db[formField.dboptions] === "function"
      ? it.$db[formField.dboptions]()
      : it.$db.shopData[formField.dboptions]
  const map = data.reduce((agg, cur) => {
    agg[cur.id] = cur.name
    return agg
  }, {})
  return values.map(d => ({ id: d, name: map[d] }))
}

const fetchRemote = async (it: any, formField: any, values: any[]) => {
  const url = REMOTE_DATA_ENDPOINTS[formField.remote]
  const { data } = await it.$axios.get(`${url}${values.join(",")}`)
  const map = data.reduce((agg, cur) => {
    agg[cur.id] = cur.name
    return agg
  }, {})
  return values.map(d => ({ id: d, name: map[d] }))
}

const fetchOptions = (it: any, formField: any, values: any[]) => {
  const map = formField.options.reduce((agg, cur) => {
    agg[cur.id] = cur.name
    return agg
  }, {})
  return values.map(d => ({ id: d, name: map[d] }))
}

const fetchNames = async (it: any, formField: any, values: any[]) => {
  if (!values) return
  if (formField.options) {
    return fetchOptions(it, formField, values)
  } else if (formField.dboptions) {
    return fetchDbOptions(it, formField, values)
  } else if (formField.remote) {
    return fetchRemote(it, formField, values)
  } else {
    return values.map(d => ({ id: d, name: d }))
  }
}

const defaultOptions = {
  tab: it => ({
    labelInterpolationFnc: value => {
      const set = it.chartConfig.agg_set?.[0].fields?.[0] || it.chartConfig.agg_set?.[0]
      if (!set) return typeof value === "object" ? value.name : value
      const field = getFormField(it, set.name)
      const formatter = CUSTOM_FORMATTERS[field.unit] || CUSTOM_FORMATTERS[field.type]
      return formatter?.(it, value) || (typeof value === "object" ? value.name : value)
    },
  }),
  pie: it => ({
    donut: false,
    plugins: [
      ChartistLegend({
        clickable: false,
        colors: index => {
          return PS_COLORS[((it.widget as any).id + index) % PS_COLORS.length]
        },
      }),
      ChartistTooltip({
        appendToBody: true,
        tooltipFnc: (meta: string, value: any) => {
          if (!meta) meta = getAxisName(it, "")
          return `${value} (${meta})`
        },
      }),
    ],
    chartPadding: 35,
    labelPosition: "outside",
    labelOffset: 20,
    labelDirection: "neutral",
    labelInterpolationFnc: value => {
      const idx = (it.chartData.rawLabels || it.chartData.labels).indexOf(typeof value === "object" ? value.id : value)
      value = it.chartData.series[idx]
      const percentage =
        Math.round(
          (value /
            it.chartData.series.reduce(function (a, b) {
              return a + b
            }, 0)) *
            100.0 *
            100.0
        ) / 100.0
      if (percentage < 1) return ""
      return `${percentage}%`
    },
  }),
  line: it => ({
    chartPadding: {
      right: 20,
      top: 0,
      bottom: 10,
    },
    low: 0,
    stretch: true,
    axisY: {
      scaleMinSpace: 50,
      offset: 80,
      labelInterpolationFnc: value => {
        const set = it.chartConfig.agg_set?.[0].fields?.[0]
        if (!set) return typeof value === "object" ? value.name : value
        const field = getFormField(it, set.name)
        const formatter = CUSTOM_FORMATTERS[field.unit] || CUSTOM_FORMATTERS[field.type]
        return formatter?.(it, value) || (typeof value === "object" ? value.name : value)
      },
    },
    axisX: {
      scaleMinSpace: 50,
      offset: 60,
      labelInterpolationFnc: value => {
        const set = it.chartConfig.agg_set?.[0]
        if (!set) return typeof value === "object" ? value.name : value
        const field = getFormField(it, set.name)
        const formatter =
          CUSTOM_FORMATTERS[set.type]?.[set.interval] || CUSTOM_FORMATTERS[field.unit] || CUSTOM_FORMATTERS[field.type]
        return formatter?.(it, value) || (typeof value === "object" ? value.name : value)
      },
    },
    plugins: [
      ChartistTooltip({
        appendToBody: true,
        tooltipFnc: (meta: string, value: any) => {
          const set = it.chartConfig.agg_set?.[0].fields?.[0]
          if (!set) return `${typeof value === "object" ? value.name : value} (${meta})`
          const field = getFormField(it, set.name)
          const formatter = CUSTOM_FORMATTERS[field.unit] || CUSTOM_FORMATTERS[field.type]
          const formattedValue = formatter?.(it, value) || (typeof value === "object" ? value.name : value)
          return `${formattedValue} (${meta})`
        },
      }),
      ChartistAxistitle({
        axisX: {
          axisTitle: it.chartConfig.options.dim_labels?.[0] || getAxisName(it, it.chartConfig.agg_set[0]),
          axisClass: "ct-axis-title",
          offset: {
            x: 0,
            y: 50,
          },
          textAnchor: "middle",
        },
        axisY: {
          axisTitle: it.chartConfig.options.dim_labels?.[1] || getAxisName(it, it.chartConfig.agg_set[0].fields?.[0]),
          axisClass: "ct-axis-title",
          offset: {
            x: 0,
            y: 0,
          },
          textAnchor: "middle",
          flipTitle: false,
        },
      }),
      // ChartistLegend({
      //   clickable: true,
      // }),
    ].filter(Boolean),
  }),
  bar: it => ({
    chartPadding: {
      right: 20,
      top: 0,
    },
    low: 0,
    stretch: true,
    axisY: {
      offset: 80,
      scaleMinSpace: 50,
      labelInterpolationFnc: value => {
        const set = it.chartConfig.agg_set?.[0].fields?.[0]
        if (!set) return typeof value === "object" ? value.name : value
        const field = getFormField(it, set.name)
        const formatter = CUSTOM_FORMATTERS[field.unit] || CUSTOM_FORMATTERS[field.type]
        return formatter?.(it, value) || (typeof value === "object" ? value.name : value)
      },
    },
    axisX: {
      offset: 60,
      scaleMinSpace: 50,
      labelInterpolationFnc: value => {
        const set = it.chartConfig.agg_set?.[0]
        if (!set) return typeof value === "object" ? value.name : value
        const field = getFormField(it, set.name)
        const formatter =
          CUSTOM_FORMATTERS[set.type]?.[set.interval] || CUSTOM_FORMATTERS[field.unit] || CUSTOM_FORMATTERS[field.type]
        return formatter?.(it, value) || (typeof value === "object" ? value.name : value)
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 10,
      },
    },
    plugins: [
      ChartistTooltip({
        appendToBody: true,
        tooltipFnc: (meta: string, value: any) => {
          const set = it.chartConfig.agg_set?.[0].fields?.[0]
          if (!set) return `${typeof value === "object" ? value.name : value} (${meta})`
          const field = getFormField(it, set.name)
          const formatter = CUSTOM_FORMATTERS[field.unit] || CUSTOM_FORMATTERS[field.type]
          const formattedValue = formatter?.(it, value) || (typeof value === "object" ? value.name : value)
          return `${formattedValue} (${meta})`
        },
      }),
      ChartistAxistitle({
        axisX: {
          axisTitle: it.chartConfig.options.dim_labels?.[0] || getAxisName(it, it.chartConfig.agg_set[0]),
          axisClass: "ct-axis-title",
          offset: {
            x: 0,
            y: 50,
          },
          textAnchor: "middle",
          ticks: {
            autoSkip: true,
            maxTicksLimit: 20,
          },
        },
        axisY: {
          axisTitle: it.chartConfig.options.dim_labels?.[1] || getAxisName(it, it.chartConfig.agg_set[0].fields?.[0]),
          axisClass: "ct-axis-title",
          offset: {
            x: 0,
            y: 0,
          },
          textAnchor: "middle",
          flipTitle: false,
        },
      }),
      // ChartistLegend({
      //   clickable: true,
      // }),
    ].filter(Boolean),
  }),
}

const CUSTOM_FORMATTERS = {
  histogram: {
    month: (it, val) => {
      const parsed = new Date(val)
      return `${parsed.toLocaleString(it.$db.broker.locale || "de", { month: "long" })} ${parsed.getFullYear()}`
    },
    quarter: (it, val) => {
      const parsed = new Date(val)
      return `${{ 0: "Q1", 3: "Q2", 6: "Q3", 9: "Q4" }[parsed.getMonth()]} ${parsed.getFullYear()}`
    },
    year: (it, val) => {
      const parsed = new Date(val)
      return parsed.getFullYear()
    },
  },
  currency: (it, val) => Filters.numberToCurrency(val, it.$db.shopData.currency || "€"),
  number: (it, val) => Filters.prettyNumber(val),
  date: (it, val) => Filters.date(val),
  boolean: (it, val) => (val ? it.$t("formattedValue.yes") : it.$t("formattedValue.no")),
}

export default defineComponent({
  components: { WidgetContainer, Tiles, TablePopover },
  props: {
    widget: {} as any,
    config: {} as any,
  },
  data() {
    return {
      chartData: null as any,
      chartDataLoading: false,
      detailPer: 10,
      detailData: null as any,
      detailFilters: null as any,
    }
  },
  watch: {
    widget: {
      handler() {
        this.detailData = null
        this.fetchAggregateData()
      },
    },
  },
  computed: {
    chartDataAvailable(): boolean {
      return Array.isArray(this.chartData?.series) ? this.chartData?.series.length !== 0 : !!this.chartData?.series
    },
    chartConfig(): any {
      const chartConfig = (this.widget as any).chartConfig
      return chartConfig
    },
    chartOptions(): any {
      return {
        responsive: true,
        fullWidth: true,
        ...defaultOptions[this.chartType as string](this),
        ...this.chartConfig.options,
      }
    },
    pluralizedEntity(): string {
      return getPluralizedName(this.chartConfig.entity)
    },
    aggregateUrl(): string {
      return AGGREGATE_ENDPOINTS[this.chartConfig.entity]
    },
    detailUrl(): string {
      return DETAIL_ENDPOINTS[this.chartConfig.entity]
    },
    chartType(): string {
      return this.chartConfig.type
    },
    chartTypeCapital(): string {
      return this.chartType && (this.chartType as any).length
        ? (this.chartType as any)[0].toUpperCase() + (this.chartType as any).slice(1)
        : undefined
    },
    chartRatio(): string {
      return ["line", "bar", "pie"].includes(this.chartType) ? "ct-square" : ""
    },
    eventHandlers(): any {
      const self = this
      return [
        {
          event: "draw",
          fn(data) {
            if (["point", "slice", "bar"].includes(data.type)) {
              data.element._node.onclick = _ => {
                self.onClick({
                  ...data,
                  value: { x: data.value.x || self.chartData.labels[data.index], y: data.value.y },
                })
              }
            }
            if (["pie"].includes(self.chartType)) {
              if (["slice"].includes(data.type)) {
                const color = PS_COLORS[((self.widget as any).id + data.index) % PS_COLORS.length]
                data.element.attr({
                  style: `fill: ${color}`,
                })
              }
            } else if (["bar", "line"].includes(self.chartType)) {
              if (["point", "bar", "line"].includes(data.type)) {
                const color = PS_COLORS[((self.widget as any).id + data.seriesIndex) % PS_COLORS.length]
                data.element.attr({
                  style: `stroke: ${color}`,
                })
              }
            }
          },
        },
      ]
    },
    detailColumns(): any {
      const axis1 = this.chartConfig.agg_set?.[0].fields?.[0]
      const axis2 = this.chartConfig.agg_set?.[0]
      const renderColumn = field =>
        !!field
          ? {
              key: field.name,
              name: field.name,
              title: getLabel(this, field.name),
              width: 200,
              render: val => {
                const formField = getFormField(this, field.name)
                if (formField.options) {
                  return fetchOptions(this, formField, [val])?.[0]?.name || val
                }
                if (formField.dboptions) {
                  return fetchDbOptions(this, formField, [val])?.[0]?.name || val
                }
                const formatter =
                  CUSTOM_FORMATTERS[formField.unit?.toLowerCase()] || CUSTOM_FORMATTERS[formField.type?.toLowerCase()]
                return formatter?.(this, val) || val
              },
            }
          : undefined
      const columns = DEFAULT_DETAIL_COLUMNS[this.chartConfig.entity]
        .map(c => ({ ...c, title: getLabel(this, c.name) }))
        .concat([renderColumn(axis1), renderColumn(axis2)].filter(Boolean))
      return columns
    },
  },
  methods: {
    onClick(data) {
      const set = this.chartConfig.agg_set?.[0]
      let filters
      if (set.type === "histogram") {
        const gte = data.value.x
        const lt = data.axisX.ticks[data.axisX.ticks.indexOf(data.value.x) + 1]
        filters = {
          ...this.chartConfig.filter_set,
          must: [...(this.chartConfig.filter_set?.must || []), { range: { [set.name]: { gte, lt } } }],
        }
      } else {
        let value = data.value.x
        if (typeof value === "object") {
          value = value.id
        }
        if (!!value && this.chartConfig.agg_set?.[0]) {
          const field = getFormField(this, set.name)
          const additionalFilters = field.render
            ? buildQuery([{ renderedQuery: field.render?.(value), value: 0 }], this.$db)
            : {
                must: [{ term: { [set.name]: value } }],
              }
          filters = {
            must: [...(this.chartConfig.filter_set?.must || []), ...(additionalFilters?.must || [])],
            must_not: [...(this.chartConfig.filter_set?.must_not || []), ...(additionalFilters?.must_not || [])],
          }
        } else {
          filters = this.chartConfig.filter_set
        }
      }
      this.detailFilters = filters
      this.fetchDetailData({ page: 1, per: this.detailPer })
    },
    async fetchDetailData(params = {}) {
      const { data } = await this.$axios.post(this.detailUrl, {
        filter_set: resolvePlaceholders(this.detailFilters, this.$db),
        ...params,
      })
      this.detailData = data
    },
    async fetchAggregateData() {
      if (this.chartDataLoading) return
      this.chartDataLoading = true
      const { data } = await this.$axios.post(this.aggregateUrl, {
        agg_set: this.chartConfig.agg_set,
        filter_set: resolvePlaceholders(this.chartConfig.filter_set, this.$db),
      })
      this.chartData = await this.formatData(data.data, this.chartConfig)
      this.chartDataLoading = false
    },
    async formatData(data, config) {
      if (!data) return
      data = data?.[0] // we only support a single metric for now
      data.series = data.series?.map((d, i) => {
        if (!["line", "bar", "pie"].includes(config.type) || !!d.name) return d
        return Array.isArray(d) ? d : [d]
      }) // ensure data is normalized
      data.series = data.series?.map((d, i) => {
        if (!["line", "bar", "tab"].includes(config.type) || !!d.name) return d
        const prop = config.agg_set?.[0]?.fields?.[i]
        return {
          name: config.options.data_labels?.[i] || getMetricName(this, prop),
          data: d,
        }
      }) // ensure data has labels
      if (["pie", "tab"].includes(config.type) && typeof data.series?.[0] === "object") {
        data.series = data.series?.[0]
      } // pie/bar chart does not support multiple series
      if (config.agg_set?.[0].type === "terms") {
        const agg_set = config.agg_set?.[0]
        const field = getFormField(this, agg_set.name)
        if (field) {
          data.rawLabels = data.labels
          data.labels = await fetchNames(this, field, data.labels)
        }
      } // load labels for terms
      return data
    },
    onChangeModalState(visible: boolean) {
      if (!this.$parent) return
      if (visible) {
        this.$parent.$el.classList.add("vue-grid-focus")
      } else {
        this.$parent.$el.classList.remove("vue-grid-focus")
      }
    },
  },
  mounted() {
    this.fetchAggregateData()
  },
})
</script>

<style lang="scss">
.ct-legend {
  position: relative;
  z-index: 10;
  float: right;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 5px;
  border-radius: 2px;

  li {
    position: relative;
    padding-left: 23px;
    margin-bottom: 3px;

    i {
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      border: 3px solid transparent;
      border-radius: 2px;
      margin-top: 5px;
    }
  }
  li.inactive {
    i {
      background: transparent !important;
    }
  }

  &.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.ct-label {
  color: #262626;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: rgba(255, 255, 255, 0.6);
  color: #262626;
  font-family: Oxygen, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top-color: rgba(255, 255, 255, 0.6);
  }
  &.tooltip-show {
    opacity: 1;
  }
}

.ct-area,
.ct-line {
  pointer-events: none;
}
</style>

import { UseConfigurationAPI, UseConfigurationUpdateFunction } from "./types/integration-page"
import { IntegrationConfiguration, IntegrationDynamicConfiguration } from "./types/integration-service"

// could be the case that we somehow mixed up the static configuration and the dynamic configuration
// this function will remove the static configuration from the dynamic configuration
export const cleanDynamicConfiguration = (
  configuration: IntegrationConfiguration | IntegrationDynamicConfiguration
): IntegrationDynamicConfiguration => {
  const { hidden: _1, requiresConfirmOnDisable: _2, requiresConfirmLegalConsent: _3, ...cleanedConfig } = configuration
  return cleanedConfig
}

export const useDefaultConfiguration: UseConfigurationAPI = ({ configuration, onUpdate, t }) => {
  // const { t } = useI18n() // fails when it's imported here

  const onEnable: UseConfigurationUpdateFunction = async pendingChanges => {
    if (configuration.enabled) {
      return {
        status: "success",
        message: t("integrations.notifications.success.enable"),
      }
    }
    try {
      const cleanedConfig = cleanDynamicConfiguration({ ...configuration, ...pendingChanges, enabled: true })
      onUpdate(cleanedConfig)

      return { status: "success", message: t("integrations.notifications.success.enable") }
    } catch (e) {
      return {
        status: "error",
        message: t("integrations.notifications.error.enable"),
      }
    }
  }

  const onDisable: UseConfigurationUpdateFunction = async pendingChanges => {
    if (configuration.enabled === false) {
      return {
        status: "success",
        message: t("integrations.notifications.success.disable"),
      }
    }
    try {
      const cleanedConfig = cleanDynamicConfiguration({ ...configuration, ...pendingChanges, enabled: false })
      await onUpdate(cleanedConfig) // HINT: this NEEDS to be implementet by each custom integration
      return { status: "success", message: t("integrations.notifications.success.disable") }
    } catch (e) {
      return {
        status: "error",
        message: t("integrations.notifications.error.disable"),
      }
    }
  }

  const onSave: UseConfigurationUpdateFunction = async pendingChanges => {
    try {
      const saveConfig = cleanDynamicConfiguration({ ...configuration, ...pendingChanges })
      await onUpdate(saveConfig)
      return { status: "success", message: t("integrations.notifications.success.save") }
    } catch (e) {
      return {
        status: "error",
        message: t("integrations.notifications.error.save"),
      }
    }
  }

  const checkConfiguration = (_configuration: IntegrationDynamicConfiguration): boolean => {
    return true
  }

  return {
    onEnable,
    onSave,
    onDisable,
    checkConfiguration,
    allowSave: false,
    requiresValidConfiguration: false,
  }
}

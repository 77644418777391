<template>
  <section class="flex">
    <div
      v-for="(item, idx) in bubbles"
      :key="idx"
      :class="getBubbleClasses(item, bubbles[idx - 1]).textClasses"
      :style="`width: ${isLastItem(idx, bubbles) ? 'auto' : 'inherit'}`"
    >
      <div :class="getBubbleClasses(item, bubbles[idx - 1]).bubbleClasses">
        <fa-icon v-if="item.active" name="check" />
        <span v-else>{{ idx + 1 }}</span>
        <span class="bubble-label absolute">{{ item.label }}</span>
      </div>

      <div
        v-if="bubbles.length !== idx + 1"
        :class="['border border-gray-400', { 'border-green-500': item.active }]"
        style="width: 100%; height: 0; border-width: 0.5px"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
export type ProgressStepsProps = {
  items: { label: string; active: boolean }[]
}
const { items: bubbles } = defineProps<ProgressStepsProps>()

function getBubbleClasses(item, prevItem): Record<string, string> {
  const textClasses = ["flex", "items-center"]
  const bubbleClasses = ["step-bubble", "border", "rounded-full", "relative"]

  if (item.active) {
    textClasses.push("text-green-500")
    bubbleClasses.push("border-green-500")
  } else if (!prevItem || prevItem.active) {
    textClasses.push("text-blue-600")
    bubbleClasses.push("border-blue-600")
  } else {
    textClasses.push("text-gray-400")
    bubbleClasses.push("border-gray-400")
  }

  return {
    textClasses: textClasses.join(" "),
    bubbleClasses: bubbleClasses.join(" "),
  }
}

function isLastItem(index: number, list: typeof bubbles): boolean {
  return index === list.length - 1
}
</script>

<style scoped>
.step-bubble {
  min-width: 20px;
  height: 20px;
  padding: 0px !important;
  text-align: center;
  font-size: 12px;
}

.bubble-label {
  top: 24px;
  left: -64px;
  width: 150px;
}
</style>

import { ref } from "vue"
import DataLakeTrackingService from "./services/data-lake-tracking-service"

export type TrackingEvent = {
  feature: string
  action: string
  metadata: Record<string, unknown>
}

export const useTracking = () => {
  const events = ref<TrackingEvent[]>([])

  const sendEvents = async () => {
    if (events.value.length > 0) {
      await DataLakeTrackingService.trackEvents(events.value)
      events.value = []
    }
  }

  // Send tracking events every 10 seconds
  setInterval(sendEvents, 10_000)

  return {
    trackEvent: async (trackingEvent: TrackingEvent) => {
      events.value.push(trackingEvent)
    },
  }
}

<template>
  <Suspense>
    <polygon-picker
      v-model="record.geometry"
      :color="$db.shopData.color"
      :international-country="$db.shopData.internationalCountry"
      :international="$db.shopData.sellsInternationally"
      :hint="$t('client.queries.form.locationHint')"
    />
  </Suspense>
</template>

<script>
import { defineComponent, Suspense } from "vue"
import PolygonPicker from "@/components/gmaps/polygon-picker.vue"

export default defineComponent({
  props: ["record", "field"],
  components: {
    PolygonPicker,
  },
})
</script>

import config from "@/config/config"
import { Loader } from "@googlemaps/js-api-loader"
import { onMounted, ref } from "vue"

const GOOGLE_MAP_CONFIG = { apiKey: config.GMAPS_KEY, version: "3.59.5", language: "de-DE" }

const loader = new Loader(GOOGLE_MAP_CONFIG)

export const loadGoogleAPI = async (type: "maps" | "places" | "drawing" | "core") => {
  return await loader.importLibrary(type)
}

export const getGoogleAPI = async () => {
  const core = await loader.importLibrary("core")
  const maps = await loader.importLibrary("maps")
  const places = await loader.importLibrary("places")
  const drawing = await loader.importLibrary("drawing")
  return { core, maps, places, drawing }
}

export function useGoogleAPI() {
  const core = ref<google.maps.CoreLibrary | null>(null)
  const maps = ref<google.maps.MapsLibrary | null>(null)
  const places = ref<google.maps.PlacesLibrary | null>(null)
  const drawing = ref<google.maps.DrawingLibrary | null>(null)
  const isLoading = ref(true)
  const error = ref(null)

  onMounted(async () => {
    try {
      const apis = await getGoogleAPI()
      core.value = apis.core
      maps.value = apis.maps
      places.value = apis.places
      drawing.value = apis.drawing
    } catch (err) {
      error.value = err
    } finally {
      isLoading.value = false
    }
  })

  return {
    core,
    maps,
    places,
    drawing,
    isLoading,
    error,
  }
}

<template>
  <component
    v-bind="$attrs"
    :is="componentTag"
    :class="[
      'tableActions-action inline-flex items-center ',
      { 'tableActions-action-active': active, disabled: isDisabled },
      `tableActions--${size}`,
    ]"
    :disabled="isDisabled"
    @click="click"
  >
    <slot>
      <span v-if="loading" class="spinner tableActions-actionIcon"></span>
      <fa-icon v-else-if="icon" :name="icon" class="tableActions-actionIcon" />
      {{ title }}
    </slot>
  </component>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useRouter } from "vue-router"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

const emit = defineEmits(["click"])
const router = useRouter()

export type PsButtonProps = {
  icon: string
  title: string
  active: boolean
  visit: string
  loading?: boolean
  disabled?: boolean
  newTab?: boolean
  size?: "normal" | "small"
  componentTag?: string
}

const {
  icon,
  title,
  active,
  visit,
  loading,
  disabled,
  newTab,
  size = "normal",
  componentTag = "button",
} = defineProps<PsButtonProps>()

const click = e => {
  if (visit && newTab) {
    e.stopPropagation()
    window.open(visit)
  } else if (visit) {
    router.push(visit)
  } else {
    emit("click", e)
  }
}

const isDisabled = computed(() => coerceBooleanAttribute(disabled))
</script>

<style>
.tableActions--small {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 22px;
  font-size: 0.85rem;
}
</style>

<script lang="ts" setup>
import { Connection, IdealistaScope, IdealistaAddressVisibility, IdealistaLocationPrecision } from "@/interfaces"
import { useI18n } from "vue-i18n"
import { RouterLink } from "vue-router"

const { item } = defineProps<{ item: Connection }>()
const { t } = useI18n()

const scopeOptions = Object.entries(IdealistaScope).map(([key, value]) => ({
  name: t(`admin.connections.idealista.scope.${key}`),
  id: value,
}))

const addressVisibilityOptions = Object.entries(IdealistaAddressVisibility).map(([key, value]) => ({
  name: t(`admin.connections.idealista.addressVisibility.${key}`),
  id: value,
}))

const locationPrecisionOptions = Object.entries(IdealistaLocationPrecision).map(([key, value]) => ({
  name: t(`admin.connections.idealista.locationPrecision.${key}`),
  id: value,
}))
</script>

<template>
  <form-section>
    <form-row :title="$t('admin.connections.idealista.feedKey')" :hint="$t('admin.connections.idealista.feedKeyDesc')">
      <nice-input
        size="small"
        :placeholder="$t('admin.connections.idealista.feedKeyPlaceholder')"
        v-model="item.idealistaFeedKey"
      />
    </form-row>
    <form-row :title="$t('admin.connections.idealista.defaultScope')">
      <nice-select v-model="item.idealistaScope" :options="scopeOptions" />
    </form-row>
    <form-row :title="$t('admin.connections.idealista.defaultAddressVisibility')">
      <nice-select v-model="item.idealistaAddressVisibility" :options="addressVisibilityOptions" />
    </form-row>
    <form-row :title="$t('admin.connections.idealista.defaultLocationPrecision')">
      <nice-select v-model="item.idealistaLocationPrecision" :options="locationPrecisionOptions" />
    </form-row>
  </form-section>
</template>

<template>
  <span
    @click="$emit('click', $event)"
    :class="[`nice-label label--size-${size}`, { 'label--clickable': clickable }]"
    v-bind="$attrs"
  >
    <span class="nice-label--bg" :class="bgClass" :style="bgStyle"></span>
    <slot>
      {{ label }}
    </slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue"

export interface NiceLabelProps {
  clickable?: boolean
  label?: string
  color?: string
  size?: "default" | "small" | "mini"
  bgClass?: string
}

defineEmits(["click"])

const { bgClass = "bg-gray-400", color, size = "default", label } = defineProps<NiceLabelProps>()

const bgStyle = computed(() => (color ? { "background-color": color } : {}))
</script>

<style>
.nice-label {
  padding: 0 7px;
  height: 18px;
  line-height: 18px;
  font-weight: 400;
  font-size: 13px;
  color: #222222;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
  background: transparent;
  position: relative;
  font-size: 13px;
  letter-spacing: 0.1px;
  padding: 0 7px;
  cursor: inherit;
}

.nice-label.label--clickable {
  cursor: pointer;
}

.nice-label--bg {
  opacity: 0.25;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.nice-label.label--size-small {
  font-size: 11px;
  padding: 0 4px;
  height: 16px;
  line-height: 16px;
  vertical-align: text-top;
}

.nice-label.label--size-mini {
  height: 14px;
  line-height: 14px;
  padding: 0 3px;
  font-size: 10px;
}
</style>

// global cache key for shop data in indexedDB
export const GLOBAL_SHOP_DATA_KEY = "shop-data"

// maximum depth of the folder tree for outlook folders (navigation pane and folder settings)
export const MAX_FOLDER_TREE_DEPTH = 10

export const MAX_SIGNED_INT = 2147483647

// magic number for permissions indicating that the permission applies to the contact person
export const BROKER_ID_PLACEHOLDER_FOR_CONTACT_PERSON = -1

export const NO_SORTING_KEY = "*STANDARD*"

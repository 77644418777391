<template>
  <div class="mailingStatButton" @click.prevent="$emit('click')" :style="activeStyle">
    <span class="mb-1">{{ label }}</span>
    <h5 class="text-2xl" :style="{ color: color }">
      <span v-if="total" class="count-percentage">{{ percentage }}%</span>
      <span class="count-absolute">{{ count }}</span>
    </h5>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"

export type StatButtonProps = {
  label: string
  count: number
  total: number
  color?: string
  selected?: boolean
}
const { label, count, total, color = "#0f55eb", selected = false } = defineProps<StatButtonProps>()

defineEmits(["click"])

const activeStyle = computed(() => {
  if (selected) {
    return {
      "border-color": color,
    }
  }
})
const percentage = computed(() => {
  return total ? Math.round((count / total) * 100) : 0
})
</script>

<style scoped>
.mailingStatButton {
  flex: 1;
  border: 1px solid #dddddd;
  border-radius: 4px;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mailingStatButton:not(:last-child) {
  margin-right: 0.75rem;
}

.mailingStatButton .count-percentage ~ .count-absolute {
  display: none;
}
.mailingStatButton:hover .count-absolute {
  display: initial;
}
.mailingStatButton:hover .count-percentage {
  display: none;
}
</style>

import { TrackingEvent } from "../use-tracking"
import APIMapping from "./api-mapping"
import { APIClient } from "./base-client"

class DataLakeTrackingService extends APIClient {
  constructor() {
    super(APIMapping.dataLakeTrackingService)
  }

  async trackEvents(events: TrackingEvent[]) {
    return this.invokeApiWithErrorHandling("/events", "POST", {
      events,
    })
  }
}

export default new DataLakeTrackingService()

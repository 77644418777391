<template>
  <connection-item
    :title="title"
    :desc="rs.name"
    :href="url"
    :disableRemove="disableRemove"
    :disableEdit="disableEdit"
    @remove="remove"
    @edit="$emit('edit', rs)"
  >
    <span>
      {{ title }}
      <span class="text-muted ml-1">{{ rs.name }}</span>
      <tooltip v-if="rs.transferClientData" :content="$t('detailView.relationship.transfer')">
        <fa-icon class="text-gray-600 ml-1" name="copy" />
      </tooltip>
    </span>
  </connection-item>
</template>

<script>
import ConnectionItem from "./ConnectionItem"

export default {
  emits: ["remove", "disableEdit", "disableRemove"],
  props: ["rs", "recordType"],
  components: {
    ConnectionItem,
  },
  methods: {
    remove() {
      this.$warn(
        {
          title: this.$t("detailView.relationship.delete"),
          desc: this.$t("detailView.relationship.delConfirm"),
          confirmButtonText: this.$t("detailView.relationship.delBtn"),
          redButton: true,
        },
        () => {
          this.$emit("remove", this.rs)
        }
      )
    },
  },
  computed: {
    url() {
      if (this.rs.client) return `/contacts/clients/${this.rs.client.id}`
      if (this.recordType == "client" && this.rs.property) return `/portfolio/properties/${this.rs.property.id}`
      if (this.recordType == "client" && this.rs.project) return `/portfolio/projects/${this.rs.project.id}`
      if (this.rs.relatedClient) return `/contacts/clients/${this.rs.relatedClient.id}`
    },
    title() {
      if (this.rs.client) return this.rs.client.name
      if (this.recordType == "client" && this.rs.property) return this.rs.property.name
      if (this.recordType == "client" && this.rs.project) return this.rs.project.name
      if (this.rs.relatedClient) return this.rs.relatedClient.name
      return this.$t("detailView.relationship.deleted")
    },
  },
}
</script>

<template>
  <span>
    <template v-for="translation in record.translations" :key="translation.locale">
      <form-row :title="`${fieldLabel} ${LOCALE_FLAGS[translation.locale] || translation.locale} `">
        <nice-input
          size="small"
          v-model="translation[fieldName]"
          @update:model-value="toggleDestroyMark(translation)"
        />
      </form-row>
    </template>
  </span>
</template>

<script setup lang="ts">
import { useAvailableShopLocales, LOCALE_FLAGS, TranslatableRecord, Translation } from "@/core/i18n"
import { watch } from "vue"

const shopLocales = useAvailableShopLocales()

const { record, fieldName, fieldLabel } = defineProps<{
  record: TranslatableRecord
  fieldName: string
  fieldLabel: string
}>()

const toggleDestroyMark = (translation: Translation) => {
  if (!translation.id) return

  if (translation[fieldName] === "") {
    translation._destroy = true
  } else {
    delete translation._destroy
  }
}

watch(record.translations, newVal => {
  shopLocales.forEach(locale => {
    if (!newVal.find(t => t.locale === locale)) {
      newVal = newVal.concat({ locale, [fieldName]: "" } as Translation)
    }
  })
})
</script>

<template>
  <nice-popover placement="bottom" width="300" trigger="click" transition="none">
    <template #reference>
      <button :class="['tableActions-action', $attrs.class]" @click="$emit('click', $event)">
        <template v-if="showCurrentSorting">
          <fa-icon name="sort" class="tableActions-actionIcon" />
          {{ $t("sortButton") }}: {{ currentOption }}
          <template v-if="!noSorting">
            <fa-icon v-if="order === 'asc'" name="sort-alpha-down" />
            <fa-icon v-if="order === 'desc'" name="sort-alpha-up" />
          </template>
        </template>
        <template v-else>
          <fa-icon name="sort" class="tableActions-actionIcon" />
          {{ $t("sortButton") }}
        </template>
      </button>
    </template>
    <div class="el-popover-inner">
      <div class="searchFilter">
        <div class="fluid-item">
          <div class="fluid">
            <div class="fake-select">
              <select :value="sortBy" class="form-control" @change="$emit('update:sort-by', $event.target.value)">
                <option v-for="option in sortedOptions" :key="option.value" :value="option.value">
                  {{ getOptionTitle(option, optionsType) }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="!noSorting">
            <nice-radio-group
              :model-value="order"
              @update:model-value="$emit('update:order', $event)"
              :options="[
                { id: 'asc', icon: 'sort-alpha-down' },
                { id: 'desc', icon: 'sort-alpha-up' },
              ]"
            />
          </div>
        </div>
        <div class="text-yellow-700 text-xs ml-1 mt-1">{{ message }}</div>
      </div>
    </div>
  </nice-popover>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { NO_SORTING_KEY } from "@/config/constants"

defineEmits(["update:sort-by", "update:order", "click"])

export type SortOption = { label: string; value: string; fieldName?: string }
export type SortButtonProps = {
  message?: string
  optionsType?: string
  order?: string
  showCurrentSorting?: boolean
  sortBy?: string
  sortOptions: SortOption[]
}
const { sortBy, order, showCurrentSorting, sortOptions, optionsType, message } = defineProps<SortButtonProps>()

function getOptionTitle(option: SortOption | undefined, optionsType: string | undefined): string {
  if (!option) return ""

  const dbItemTitle = $db.getItemFieldTitle(option.fieldName || option.value, optionsType)
  if (dbItemTitle) return dbItemTitle

  return option.label
}

const sortedOptions = computed(() => sortOptions.sort((a, b) => (a.label > b.label ? 1 : -1)))
const noSorting = computed(() => sortBy === NO_SORTING_KEY)
const currentOption = computed(() => {
  const option = sortedOptions.value.find(({ value }) => value === sortBy)
  return getOptionTitle(option, optionsType)
})
</script>

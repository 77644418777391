<template>
  <span>
    <slot :counter="counter">
      <nice-label v-if="counter > 0" class="mr-1" bg-class="bg-primary" size="small">
        <fa-icon name="comments" class="mr-1" />
        {{ counter }}
      </nice-label>
    </slot>
  </span>
</template>

<script>
export default {
  emits: ["update:counter"],
  props: {
    counter: {
      type: Number,
      default: 0,
    },
    resourceType: {
      type: String,
      required: true,
    },
    resourceId: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.$pusher.on(`comment:${this.resourceType}:${this.resourceId}:created`, () => {
      this.$emit("update:counter", this.counter + 1)
    })

    this.$pusher.on(`comment:${this.resourceType}:${this.resourceId}:destroyed`, () => {
      this.$emit("update:counter", this.counter - 1)
    })
  },
}
</script>

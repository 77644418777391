import type { WatermarkPreviewCanvas, WatermarkPreviewPositions } from "./WatermarkPreview.types"

export const previewPositions: WatermarkPreviewPositions = {
  NorthWest: {
    vertical: "start",
    horizontal: "left",
  },
  North: {
    vertical: "start",
    horizontal: "center",
  },
  NorthEast: {
    vertical: "start",
    horizontal: "right",
  },
  West: {
    vertical: "center",
    horizontal: "left",
  },
  Center: {
    vertical: "center",
    horizontal: "center",
  },
  East: {
    vertical: "center",
    horizontal: "right",
  },
  SouthWest: {
    vertical: "end",
    horizontal: "left",
  },
  South: {
    vertical: "end",
    horizontal: "center",
  },
  SouthEast: {
    vertical: "end",
    horizontal: "right",
  },
}

export const previewCanvas: WatermarkPreviewCanvas = {
  width: 256,
  height: 144,
}

import useCore from "@/plugins/use-core"
import { computed } from "vue"

export const useEmailSettings = () => {
  const { db } = useCore()

  const isOutlookFoldersEnabled = computed(
    () => db.shopData.activeFeatures.includes("msft_folders") && db.shopData.enableNativeOutlookFolders
  )

  return {
    isOutlookFoldersEnabled,
  }
}

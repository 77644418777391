<script setup lang="ts">
import type { DetailClient, Tipster } from "@/interfaces"
import useCore from "@/plugins/use-core"
import { useI18n } from "vue-i18n"

type Props = {
  client: Pick<DetailClient, "id" | "name" | "tipster">
  callout?: boolean
}

const emit = defineEmits<{
  (event: "created", tipster?: Tipster): void
  (event: "removed"): void
}>()

const { client, callout } = withDefaults(defineProps<Props>(), {
  callout: false,
})

const { api, axios } = useCore()
const { t } = useI18n()

const onCreateClick = async () => {
  try {
    const result = await api.mutation("createTipster", { clientId: client.id }, "id")
    if (!result?.id) throw new Error(t("client.createerror") as string)

    App.flashy(t("client.tipster.created", { name: client.name }))
    client.tipster = result
    emit("created", result)
  } catch (e) {
    axios.handleError(e)
  }
}

const onRemoveClick = async () => {
  if (!client.tipster) return

  try {
    await api.mutation("deleteTipster", { clientId: client.id })

    App.flashy(t("client.tipster.removed", { name: client.name }))
    client.tipster = undefined
    emit("removed")
  } catch (e) {
    axios.handleError(e)
  }
}
</script>

<template>
  <span>
    <nice-button class="larger-font" round="true" size="small" type="warning" v-if="callout" @click="onCreateClick">
      <fa-icon class="mr-1" name="lightbulb" />
      {{ $t("client.tipster.wants", { name: client.name }) }}
    </nice-button>

    <template v-else>
      <nice-button class="larger-font" type="text" v-if="!client.tipster" @click="onCreateClick">
        <!-- <fa-icon class="mr-1" name="lightbulb" /> -->
        {{ $t("client.tipster.make") }}
      </nice-button>

      <nice-button class="larger-font text-danger" type="text" v-else @click="onRemoveClick">
        <!-- <fa-icon class="mr-1" name="lightbulb-slash" /> -->
        {{ $t("client.tipster.remove") }}
      </nice-button>
    </template>
  </span>
</template>

<style lang="scss" scoped></style>

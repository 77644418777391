<template>
  <div class="flex grow">
    <columns-modal
      v-model:visible="columns.dialogVisible"
      v-model:columns="columns.data"
      @reset="resetColumns"
      :canReset="canResetColumns"
      @change="columnVisibleChanged"
    />

    <ps-data-table
      :columns="tableColumns"
      :data="data"
      :per="per"
      @update:per="$emit('update:per', $event)"
      :page-sizes="pageSizes"
      :page="page"
      @update:page="$emit('update:page', $event)"
      :total="total"
      :selected="selected"
      :resizable="resizable"
      @update:selected="$emit('update:selected', $event)"
      :loading="loading"
      :store-columns="storeColumns"
    >
      <template v-slot:name="{ resource }">
        <p>
          <router-link :to="resource.path" class="link">
            <birthday-contact :dob="resource.dob">
              {{ resource.name }}
            </birthday-contact>
          </router-link>
          <fa-icon name="lock" class="ml-1 text-red-700" v-if="resource.no_access" />
          <fa-icon name="unlock" class="ml-1" v-if="resource.locked && !resource.no_access" />
        </p>
      </template>
      <template v-slot:children_name="{ resource }">
        <p>
          <router-link :to="resource.path" class="link">
            {{ resource.children_name.join(", ") }}
          </router-link>
        </p>
      </template>
      <template v-slot:phone="{ resource }">
        <p v-redacted class="txt-overflow">
          <a :href="$customFilters.phonify(resource.phone)" :data-client-id="resource.id" class="link">
            {{ resource.phone }}
          </a>
        </p>
      </template>
      <template v-slot:email="{ resource }">
        <p v-redacted class="txt-overflow">
          <a class="link" @click.prevent="quickviewMail(resource.id)">{{ resource.email }}</a>
        </p>
      </template>
      <template v-slot:last_contact_at="{ resource }">
        <date-cell :datetime="resource.last_contact_at" />
      </template>
      <template v-slot:groups="{ resource }">
        {{ resource.groups.join(", ") }}
      </template>
      <template v-slot:dob="{ resource }">{{ $customFilters.date(resource.dob) }}</template>
      <template v-slot:age="{ resource }">{{ $customFilters.age(resource.dob) }}</template>
      <template v-slot:followup_date="{ resource }">{{ $customFilters.date(resource.followup_date) }}</template>
      <template v-slot:created_at="{ resource }">
        <date-cell :datetime="resource.created_at" />
      </template>
      <template v-slot:keep_data_till="{ resource }">{{ $customFilters.date(resource.keep_data_till) }}</template>
      <template v-slot:status="{ resource }">
        <client-status-label :clientStatusId="resource.status_id" @click="openEditForm(resource)" />
      </template>
      <template v-slot:client_reason_id="{ resource }">
        <span :title="resource.clientReason ? resource.clientReason.name : ''">
          {{ resource.clientReason ? resource.clientReason.name : "" }}
        </span>
      </template>
      <template v-slot:team_id="{ resource }">
        <p v-if="resource.team">{{ resource.team.name }}</p>
      </template>
      <template v-slot:broker="{ resource }">
        <avatar v-if="resource.broker" :broker="resource.broker" />
      </template>
      <template v-slot:second_broker="{ resource }">
        <avatar v-if="resource.second_broker" :broker-id="resource.second_broker" />
      </template>
      <template v-slot:creator_id="{ resource }">
        <avatar v-if="resource.creator" :broker="resource.creator" />
      </template>
      <template v-slot:client_source="{ resource }">
        {{ resource.clientSource ? resource.clientSource.name : "" }}
      </template>
      <template v-slot:salutation="{ resource, col }">{{ col.SALUTATIONS[resource.salutation] }}</template>
      <template v-slot:saved_queries="{ resource }">
        <router-link v-if="resource.saved_queries_count" :to="`/contacts/clients/${resource.id}/queries`">
          <fa-icon name="check" />
        </router-link>
        <span v-else>–</span>
      </template>
      <template v-slot:rating="{ resource }">
        <nice-star-rating :rating="resource.rating" />
      </template>
      <template v-slot:gdpr_status="{ resource }">
        <gdpr-status :gdpr-status="resource.gdpr_status" />
      </template>
      <template v-slot:accept_contact="{ resource }">
        <accept-contact :accepted="resource.accept_contact" />
      </template>
      <template v-slot:property_mailing_wanted="{ resource }">
        <fa-icon v-if="resource.property_mailing_wanted" name="check" class="text-green-500" />
        <span v-else>–</span>
      </template>
      <template v-slot:home_url="{ resource }">
        <a v-if="resource.website" :href="$customFilters.urlify(resource.website)" target="_blank">
          {{ resource.website }}
        </a>
      </template>
      <template v-slot:newsletter="{ resource }">
        <span v-if="resource.newsletter === null">–</span>
        <fa-icon v-else-if="resource.newsletter" name="check"></fa-icon>
        <fa-icon v-else name="times"></fa-icon>
      </template>
      <template v-for="cf in customFields" v-slot:[cf.fieldName]="{ resource }" :key="cf.name">
        <custom-value :resource="resource" :cf="cf" />
      </template>
      <template v-slot:last_cell="{ resource }">
        <slot name="last_cell" v-bind="{ resource }" />
      </template>
      <template v-slot:client_property_exists="{ resource }">
        <fa-icon v-if="resource.client_property_exists" name="check" />
        <span v-else>–</span>
      </template>
      <template v-slot:client_property_start_date="{ resource }">
        {{ $customFilters.date(resource.client_property_start_date) }}
      </template>
      <template v-slot:client_property_stage="{ resource }">
        <nice-label
          v-if="resource.client_property_stage"
          :label="resource.client_property_stage.name"
          :color="resource.client_property_stage.color"
        />
      </template>
    </ps-data-table>
    <div :class="{ visible: loading && per >= 200 }" class="loading-indicator">
      <spinner />
    </div>
    <edit-form :editable="editable" :visible="!!editable" @close="editable = null" @complete="updateEditable" />
  </div>
</template>

<script>
import PaginatableMixin from "../mixins/paginatable"
import { formatClientRow, CustomClientColumnsMixin, DEFAULT_COLUMNS } from "../config/client"
import ClientStatusLabel from "./client/StatusLabel"
import ColumnsModal from "./ColumnsModal"
import CustomValue from "./Table/CustomValue"
import EditForm from "./client/EditFormDialog"
import eventBus from "@/config/event-bus"
import NiceStarRating from "@/components/NiceStarRating.vue"
import BirthdayContact from "@/components/client/BirthdayContact.vue"

const CURRENCY_MAP = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  CHF: "CHF",
  HUF: "Ft",
  AED: "AED",
  CZK: "CZK",
  euro: "€",
  chf: "CHF",
  huf: "Ft",
  aed: "AED",
  czk: "CZK",
}

export default {
  mixins: [CustomClientColumnsMixin, PaginatableMixin],

  expose: ["fetchData", "columns", "total"],
  components: {
    ClientStatusLabel,
    ColumnsModal,
    CustomValue,
    EditForm,
    NiceStarRating,
    BirthdayContact,
  },

  props: [
    "customColumns",
    "customColumnChoices",
    "fetchClients",
    "per",
    "selected",
    "pageSizes",
    "page",
    "resizable",
    "storeColumns",
    "fetchDefaultColumns",
  ],

  emits: ["update:per", "update:page", "update:selected", "update-column-layout"],

  data() {
    return {
      data: [],
      loading: true,
      total: 0,
      editable: null,
    }
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true
        const { data, total } = await this.fetchClients()
        this.data = data.map(client => formatClientRow(client, this.$db.shopData))
        this.total = total
        this.loading = false
      } catch (e) {
        this.$axios.handleError(e)
      } finally {
        this.loading = false
      }
    },
    openEditForm(client) {
      if (!this.$db.broker.canChangeClientStatus) return
      this.editable = client
    },
    updateEditable(edited) {
      this.data = this.data.map(o => (o.id == edited.id ? edited : o))
      this.editable = null
    },
    quickviewMail(id) {
      eventBus.$emit("quick-view", {
        type: "mail",
        mode: "edit",
        params: {
          source: {
            clientIds: [id],
          },
        },
      })
    },
  },
  computed: {
    canResetColumns() {
      return !!this.fetchDefaultColumns
    },
    tableColumns() {
      const translated = DEFAULT_COLUMNS.map(d => ({ ...d, title: this.$t(`clients.formFields.${d.name}`) }))
      return translated.concat(this.userColumns).map(col => ({
        ...col,
        key: col.name,
        align: CURRENCY_MAP[col.unit] && col.name.includes("cf_") ? "right" : col.align,
      })) // Mapping muss sein, weil die columns "name" nutzen und nicht "key"
    },
    customFields() {
      return _.flatten(
        this.$db.get("customFieldGroupsForClients").map(g =>
          g.customFields.map(cf => ({
            ...cf,
            fieldName: `cf_${cf.name}`,
          }))
        )
      )
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.loading-indicator {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  height: 100%;
  transition: opacity 0.5s ease;
  justify-content: center;
  align-items: center;

  &.visible {
    pointer-events: all;
    opacity: 0.75;
  }
}
</style>

<template>
  <p class="niceBar" :style="style">
    <slot>
      {{ label }}
    </slot>
  </p>
</template>

<!-- Need to keep this script tag to prevent error: defineProps() in (script setup) cannot reference locally declared variables -->
<script lang="ts">
const COLORS = {
  light: "#999999",
  success: "#22dd33",
  warning: "#cc7700",
  danger: "#ff0000",
  info: "#3388ff",
  dark: "#999999",
}

export default {}
</script>

<script lang="ts" setup>
import { defineProps, computed } from "vue"

export type NiceBarProps = {
  label: string
  type?: "light" | "success" | "warning" | "danger" | "info" | "dark"
}

const { label, type = "success" } = defineProps<NiceBarProps>()

const style = computed(() => ({
  background: `${COLORS[type]}30`,
}))
</script>

<style scoped>
.niceBar {
  padding: 0.35rem 0.7rem;
  background: rgba(#999999, 0.15);
  color: #222222;
  border-radius: 3px;
  font-size: 0.85rem;
  margin: 0.5rem -0.7rem;
}
</style>

import { ActiveRecord } from "@/config/db"
import { ElasticSearchFilterDef, ElasticSearchQueryPart } from "@/interfaces/search-filters.types"
import _ from "lodash"
import moment from "moment"

export const groupByKey = (acc: Record<string, any>, esFilter: ElasticSearchFilterDef) => {
  const { key } = esFilter
  if (!key) {
    return acc
  }
  acc[key] = acc[key] || []
  acc[key].push(...(Array.isArray(esFilter.value) ? esFilter.value : [esFilter.value]))
  return acc
}

export const createArrayFilter = (
  fieldName: string,
  value: string | string[],
  operator: string = "contains"
): ElasticSearchFilterDef => ({
  key: Date.now(),
  type: "Array",
  column: fieldName,
  fieldName,
  operator,
  value: ([] as string[]).concat(value).filter(Boolean),
})

export const formatToESRange = (esFilter: ElasticSearchFilterDef, nested: boolean): ElasticSearchQueryPart[] => {
  const result = formatToESDynamicPartRange(esFilter, nested)
  if (!esFilter.params || !result.length) return result

  return result.map<ElasticSearchQueryPart>(res => ({ ...res, value: _.flatten([res.value, esFilter.params]) }))
}

export const formatToES = (
  esFilter: ElasticSearchFilterDef,
  nested: boolean
): ElasticSearchQueryPart | ElasticSearchQueryPart[] => {
  const result = formatToESDynamicPart(esFilter, nested)
  if (!esFilter.params) return result
  if (Array.isArray(result)) {
    if (!result.length) return result
  } else {
    if (!result.key) return result
  }

  if (!Array.isArray(result)) {
    return { ...result, value: _.flatten([result.value, esFilter.params]) }
  }

  return result.map<ElasticSearchQueryPart>(res => ({ ...res, value: _.flatten([res.value, esFilter.params]) }))
}

export const formatToESDynamicPartRange = function (
  esFilter: ElasticSearchFilterDef,
  nested: boolean
): ElasticSearchQueryPart[] {
  const lower = esFilter.find(s => s.fieldName === s.range)
  const upper = esFilter.find(s => s.fieldName !== s.range)
  const lowerFieldName = esFilter.map(s => s.range).filter(Boolean)[0]
  const upperFieldName = `${lowerFieldName}_to`
  let result: ElasticSearchQueryPart[] = []

  if ((!lower || lower.operator === "gt") && (!upper || upper.operator === "lt")) {
    // (startA === null || endB === null || startA <= endB) && (endA === null || startB === null || endA >= startB)
    if (upper && typeof upper.value === "number") {
      result = result.concat({
        key: "must",
        value: {
          bool: {
            should: [
              {
                bool: {
                  must: { range: { [lowerFieldName]: { ["lt"]: upper.value } } },
                },
              },
              {
                bool: {
                  must_not: { exists: { field: lowerFieldName } },
                },
              },
            ],
          },
        },
      })
    }

    if (lower && typeof lower.value === "number") {
      result = result.concat({
        key: "must",
        value: {
          bool: {
            should: [
              {
                bool: {
                  must: { range: { [upperFieldName]: { ["gt"]: lower.value } } },
                },
              },
              {
                bool: {
                  must_not: { exists: { field: upperFieldName } },
                },
              },
            ],
          },
        },
      })
    }

    result = result.concat({
      key: "must",
      value: {
        bool: {
          should: [
            {
              bool: {
                must: { exists: { field: upperFieldName } },
              },
            },
            {
              bool: {
                must: { exists: { field: lowerFieldName } },
              },
            },
          ],
        },
      },
    })
    return result.filter(item => item && Object.keys(item).length > 0)
  }
  return esFilter.map(v => formatToESDynamicPart(v, nested))
}

export const formatToESDynamicPart = function (
  esFilter: ElasticSearchFilterDef,
  nested: boolean = false
): ElasticSearchQueryPart | ElasticSearchQueryPart[] {
  // FIXME: why is it possible to return an array or an object?
  if (
    !["exists", "missing", "today", "thisMonth", "lastMonth", "thisYear", "lastYear"].includes(esFilter.operator) &&
    esFilter.type !== "Boolean" &&
    esFilter.value !== 0 &&
    ([undefined, NaN].includes(esFilter.value) ||
      (esFilter.type !== "Array" && esFilter.value === null) ||
      esFilter.value?.length <= 0)
  ) {
    return {} // FIXME: what should this return? undefined?
  }

  const { fieldName } = esFilter

  if (esFilter.renderedQuery) return esFilter.renderedQuery
  switch (esFilter.operator) {
    case "contains":
      return {
        key: "must",
        value: {
          bool: {
            should: _.flatten([esFilter.value])
              .map(function (v) {
                if (v === "nil" || v === "null") {
                  return { bool: { must_not: { exists: { field: fieldName } } } }
                } else {
                  return { term: { [fieldName]: v } }
                }
              })
              .concat(esFilter.null ? { bool: { must_not: { exists: { field: fieldName } } } } : undefined)
              .filter(Boolean),
          },
        },
      }
    case "doesNotContain":
      if (nested) {
        return {
          key: "must",
          value: {
            bool: {
              should: _.flatten([esFilter.value]).map(function (v) {
                if (v === "nil" || v === "null") {
                  return {
                    bool: { must_not: { exists: { field: fieldName } } },
                  }
                } else {
                  return { term: { [fieldName]: v } }
                }
              }),
            },
          },
        }
      } else {
        return {
          key: "must_not",
          value: {
            bool: {
              should: _.flatten([esFilter.value]).map(function (v) {
                if (v === "nil" || v === "null") {
                  return {
                    bool: { must_not: { exists: { field: fieldName } } },
                  }
                } else {
                  return { term: { [fieldName]: v } }
                }
              }),
            },
          },
        }
      }
    case "is":
      if (esFilter.type === "String") {
        if (fieldName == "zip_code" && esFilter.value?.includes(",")) {
          return {
            key: "must",
            value: {
              terms: { [fieldName]: esFilter.value.split(",").map(o => o.trim()) },
            },
          }
        } else {
          return {
            key: "must",
            value: {
              multi_match: {
                query: esFilter.value,
                fields: [fieldName],
                type: "phrase_prefix",
              },
            },
          }
        }
      } else {
        if (esFilter.value === false) {
          if (esFilter.type === "Array") {
            return {
              key: "must",
              value: {
                bool: {
                  should: [{ term: { [fieldName]: false } }],
                },
              },
            }
          }
          return {
            key: "must",
            value: {
              bool: {
                should: [{ term: { [fieldName]: false } }, { bool: { must_not: { exists: { field: fieldName } } } }],
              },
            },
          }
        } else {
          if (esFilter.value === "nil" || (esFilter.type === "Array" && esFilter.value === null)) {
            return { key: "must_not", value: { exists: { field: fieldName } } }
          } else if (esFilter.null) {
            return {
              key: "must",
              value: {
                bool: {
                  should: _.flatten([esFilter.value])
                    .map(v => ({ term: { [fieldName]: v } }))
                    .concat({ bool: { must_not: { exists: { field: fieldName } } } })
                    .filter(Boolean),
                },
              },
            }
          } else {
            return _.flatten([esFilter.value]).map(v => ({
              key: "must",
              value: { term: { [fieldName]: v } },
            }))
          }
        }
      }
    case "isnt":
      if (esFilter.type === "String") {
        if (fieldName == "zip_code" && esFilter.value?.includes(",")) {
          return esFilter.value.split(",").map(v => ({
            key: "must_not",
            value: { term: { [fieldName]: v.trim() } },
          }))
        } else {
          return {
            key: "must_not",
            value: {
              multi_match: {
                query: esFilter.value,
                fields: [fieldName],
                type: "phrase_prefix",
              },
            },
          }
        }
      } else {
        if (esFilter.value === "nil") {
          return { key: "must", value: { exists: { field: fieldName } } }
        } else {
          return _.flatten([esFilter.value]).map(v => ({
            key: "must_not",
            value: { term: { [fieldName]: v } },
          }))
        }
      }
    case "exists":
      if (esFilter.type === "String") {
        return [
          { key: "must", value: { exists: { field: fieldName } } },
          { key: "must_not", value: { term: { [fieldName]: "" } } },
        ]
      } else {
        return { key: "must", value: { exists: { field: fieldName } } }
      }
    case "missing":
      if (nested) {
        return { key: "must", value: { exists: { field: fieldName } } }
      } else {
        return {
          key: "must",
          value: {
            bool: {
              should: [
                esFilter.type === "String" ? { term: { [fieldName]: "" } } : undefined,
                { bool: { must_not: { exists: { field: fieldName } } } },
              ],
            },
          },
        }
      }
    case "lt":
    case "gt":
      const momentValue =
        esFilter.operator === "lt"
          ? moment(esFilter.value).endOf("day").format()
          : moment(esFilter.value).startOf("day").format()
      return {
        key: "must",
        value: {
          range: {
            [fieldName]: {
              [esFilter.operator]: esFilter.type === "Date" ? momentValue : esFilter.value,
            },
          },
        },
      }
    case "dlte":
    case "dgte":
      if (fieldName === "dob") {
        const value = `1972-${moment()
          .add(esFilter.value * (esFilter.operator === "dgte" ? -1 : 1), "days")
          .format("MM-DD")}`
        return [
          {
            key: "must",
            value: {
              range: {
                birthday: {
                  [esFilter.operator.replace("d", "")]: value,
                },
              },
            },
          },
          {
            key: "must",
            value: {
              range: {
                birthday: {
                  [esFilter.operator.includes("gte") ? "lte" : "gte"]: `1972-${moment().format("MM-DD")}`,
                },
              },
            },
          },
        ]
      } else {
        const value = `now${esFilter.value < 0 ? esFilter.value : "+" + esFilter.value}d/d`
        return {
          key: "must",
          value: {
            range: {
              [fieldName]: { [esFilter.operator.replace("d", "")]: value },
            },
          },
        }
      }
    case "today":
      const value = `1972-${moment().format("MM-DD")}`
      return { key: "must", value: { term: { birthday: value } } }
    case "thisMonth":
      return { key: "must", value: { range: { [fieldName]: { gte: "now/M" } } } }
    case "lastMonth":
      return { key: "must", value: { range: { [fieldName]: { gte: "now+1m-1M/M", lt: "now/M" } } } }
    case "thisYear":
      return { key: "must", value: { range: { [fieldName]: { gte: "now/y" } } } }
    case "lastYear":
      return { key: "must", value: { range: { [fieldName]: { gte: "now+1m-1y/y", lt: "now/y" } } } }
    case "lastXDays":
      return {
        key: "must",
        value: {
          range: {
            [fieldName]: {
              gte: `now+1m-${esFilter.value < 0 ? -esFilter.value : esFilter.value}d/d`,
              lt: "now/d",
            },
          },
        },
      }
    case "lastXMonths":
      return {
        key: "must",
        value: {
          range: {
            [fieldName]: {
              gte: `now+1m-${esFilter.value < 0 ? -esFilter.value : esFilter.value}M/M`,
              lt: "now/M",
            },
          },
        },
      }
    default:
      return {}
  }
}

export const buildQuery = (filterSet: ElasticSearchFilterDef[], db: ActiveRecord) => {
  const useElasticRangeFilter = db.featureActive("elastic_range_filter")
  const nestedGroupedByPath = _.groupBy(
    filterSet.filter(o => o.nested),
    "nested"
  )
  const nestedESFilters = _.flatten(
    Object.keys(nestedGroupedByPath).map(path => {
      let esFilterQueries
      const filters = [] as any[] // TODO: type this!
      const negativeFilters = nestedGroupedByPath[path].filter(filter =>
        ["doesNotContain", "missing"].includes(filter.operator)
      )
      if (negativeFilters.length > 0) {
        _.flatten(negativeFilters.filter(o => !o.distinct).map(o => formatToES(o, true))).forEach(esFilterQueries => {
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must_not",
              value: {
                nested: {
                  path,
                  query: {
                    bool: _.flatten([esFilterQueries]).reduce(groupByKey, {}),
                  },
                },
              },
            })
          }
        })
        esFilterQueries = _.flatten(negativeFilters.filter(o => o.distinct).map(o => formatToES(o, true))).reduce(
          groupByKey,
          {}
        )
        if (Object.keys(esFilterQueries).length > 0) {
          filters.push({
            key: "must_not",
            value: {
              nested: {
                path,
                query: {
                  bool: esFilterQueries,
                },
              },
            },
          })
        }
      }

      const remainingFilters = nestedGroupedByPath[path].filter(
        filter => !["doesNotContain", "missing"].includes(filter.operator)
      )
      if (remainingFilters.length > 0) {
        _.flatten(remainingFilters.filter(o => o.distinct).map(o => formatToES(o, true))).forEach(esFilterQueries => {
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must",
              value: {
                nested: {
                  path,
                  query: {
                    bool: _.flatten([esFilterQueries]).reduce(groupByKey, {}),
                  },
                },
              },
            })
          }
        })
        if (useElasticRangeFilter) {
          esFilterQueries = _.flatten(
            _.values(
              _.groupBy(
                remainingFilters.filter(o => !o.distinct && o.range),
                o => o.range
              )
            ).map(o => formatToESRange(o, true))
          ).reduce(groupByKey, {})
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must",
              value: {
                nested: {
                  path,
                  query: {
                    bool: esFilterQueries,
                  },
                },
              },
            })
          }
          esFilterQueries = _.flatten(
            remainingFilters.filter(o => !o.distinct && !o.range).map(o => formatToES(o, true))
          ).reduce(groupByKey, {})
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must",
              value: {
                nested: {
                  path,
                  query: {
                    bool: esFilterQueries,
                  },
                },
              },
            })
          }
        } else {
          esFilterQueries = _.flatten(remainingFilters.filter(o => !o.distinct).map(o => formatToES(o, true))).reduce(
            groupByKey,
            {}
          )
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must",
              value: {
                nested: {
                  path,
                  query: {
                    bool: esFilterQueries,
                  },
                },
              },
            })
          }
        }
      }

      return filters
    })
  )

  return _.flatten(filterSet.filter(o => !o.nested && !o.skipES).map(o => formatToES(o, false)))
    .concat(nestedESFilters)
    .reduce(groupByKey, {})
}

export const resolvePlaceholders = (
  filterSet: ElasticSearchFilterDef | ElasticSearchFilterDef[] | undefined,
  db: ActiveRecord
) => {
  if (!filterSet) return
  let str = JSON.stringify(filterSet)
  str = str.replace("{{ broker_id }}", `${db.broker.id}`)
  return JSON.parse(str) as ElasticSearchFilterDef | ElasticSearchFilterDef[]
}
